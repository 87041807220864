@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat-medium.woff2') format('woff2'),
         url('/fonts/montserrat-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/opensans-bold.woff2') format('woff2'),
         url('/fonts/opensans-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/opensans-light.woff2') format('woff2'),
         url('/fonts/opensans-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/opensans-regular.woff2') format('woff2'),
         url('/fonts/opensans-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/opensans-medium.woff2') format('woff2'),
         url('/fonts/opensans-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/opensans-semibold.woff2') format('woff2'),
         url('/fonts/opensans-semibold.woff') format('woff'); /* Fixed typo: 'openaans' to 'opensans' */
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Import other CSS files as needed */
@import "utils/config";
@import "utils/layout";

@import "components/custom-car";
@import "components/extras-item";
@import "components/footer";

@import "components/header";
@import "components/header/main-menu";
@import "components/header/settings-menu";

@import "components/login-form";
@import "components/order-form";
@import "components/order-sidebar";
@import "components/orders-list";
@import "components/orders-table";
@import "components/place";
@import "components/trash";
