.settings-menu {

    display: flex;
    align-items: center;
    font-size: 16px;

    > span {
        color: rgba(0, 0, 0, 0.45);

        @media @max-large {
            display: none;
        }
    }

    > a {
        white-space: nowrap;
        display: flex;
        align-items: center;

        .anticon {
            .ant-layout-ltr & {
                margin-left: 5px;
            }
            .ant-layout-rtl & {
                margin-right: 5px;
            }
        }

        img {
            .ant-layout-ltr & {
                margin-right: 10px;
            }
            .ant-layout-rtl & {
                margin-left: 10px;
            }

            @media @max-xsmall {
                .ant-layout-ltr & {
                    margin-right: 0;
                }
                .ant-layout-rtl & {
                    margin-left: 0;
                }
            }
        }
    }

    &--currency {
        @media @max-menu {
            margin: 0 10px;
        }
    }

    &--language {
        margin: 0 10px;
        position: relative;
        z-index: 9999;

        @media @max-menu {
            margin: 0 10px;
        }

        img {
            border: 1px solid rgba(0, 0, 0, 0.45);
            border-radius: 100px;
            padding: 1px;
        }
    }

    &__select {
        padding-top: 10px;
        margin-left: -50px;
        box-shadow: 0 0 10px #ccc;
        border-radius: 10px;
        background: #fff;
        margin-top: -28px;

        .ant-layout-ltr & {
            margin-left: -16px;
        }
        .ant-layout-rtl & {
            margin-right: -16px;
        }

        .ant-menu {
            padding-bottom: 10px;
            max-height: 200px;
            overflow: auto;
            background: none;
            border: 0;
        }

        li {
            height: 30px !important;
            line-height: 30px !important;
        }
    }

    &__trigger {
        display: flex;
        align-items: center;
        line-height: 30px !important;
        height: 30px !important;

        img {
            border: 1px solid rgba(0, 0, 0, 0.45);
            border-radius: 100px;
            padding: 1px;
            margin-right: 10px;

            .ant-dropdown-rtl & {
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }

    // New modifier: hide-on-small-mobile
    &.hide-on-small-mobile {
        

        @media @max-xsmall {
            display: none;
        }

        @media @max-xxsmall {
            display: none;
        }
    }

        // New modifier: hide-on-small-mobile
        &.hide-on-smaller-screen {
        

            @media @max-large{
                display: none;
            }
    
        }
}
