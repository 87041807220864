.order-sidebar {
    font-size: 16px;
    color: #555;
    padding: 0;
    min-width: 250px;
    

    @media @max-menu {
        padding: 25px 15px;
    }

    @media @max-xmedium {
        display: none;
        min-width: 0;
    }

    p {
        .ant-layout-ltr & {
            padding-right: 30px;
        }
        .ant-layout-rtl & {
            padding-left: 30px;
        }
    }
}

.order-sidebar__headline {
    border-bottom: 1px solid #e4e9f0;
    margin-bottom: 15px;
}

.order-sidebar__title {
    font-size: 18px;
    display: inline-block;
    border-bottom: 2px solid #72c02c;
    margin: 0 0 -2px 0;
    font-weight: 300;
    line-height: 33px;
    padding-bottom: 5px;
}

.order-sidebar__subtitle {
    font-size: 15px;
    font-weight: 700;
    color: #555;
}

.order-sidebar__last-link {
    color: #555;

    &:hover,
    &:focus {
        color: #555;
    }
}

.order-sidebar__box {
    border-radius: 8px;
    border: 1px solid #d0c5c5;
    background-color: #fbfcff;
    padding: 12px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}
