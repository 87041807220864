.main-menu {

    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &__item {

        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        @media @max-xsmall {
            flex: 1;
            justify-content: center;
        }

        @media @max-menu {
            height: 50px;
        }

        &--mobile {
            display: none;
            @media @max-menu {
                display: block;

                .ant-layout-rtl & {
                    border-right: 1px solid #f0f0f0;
                }
            }
        }

        &--home {
            @media @max-small {
                // max-width: 50px;


                .main-menu__item-text {
                    padding: 0 18px;
                }
            }
        }

        &--logout {
            @media @max-small {
            }
        }

        &--pull {
            .ant-layout-ltr & {
                margin-left: auto;
            }
            .ant-layout-rtl & {
                margin-right: auto;
            }
        }

        &--default {
            @media @max-small {

                .ant-layout-ltr & {
                    border-left: none;
                }
                .ant-layout-rtl & {
                    border-right: 1px solid #f0f0f0;
                }

                .anticon {
                    display: none;
                }
            }
        }

    }

    &__item-link {

        display: block;
        color: @color-primary;
        padding: 50px 0;
        height: 100%;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.3s;

        .anticon {
            color: #005093;

            .ant-layout-ltr & {
                margin-right: 10px;
            }
            .ant-layout-rtl & {
                margin-left: 10px;
            }
        }

        &:hover,
        &:focus,
        &.is-active {
            color: @color-xlight;
        }

    }

    &__item-text {

        display: flex;
        flex-flow: row nowrap;
        //align-items: center;
        padding: 0 10px;
        height: 100%;
        font-size: 16px;

    }

}

.deskMenu {
    display: block;
    @media @max-small {
        display: none;
    }
}

#sidebar {
    .anticon-profile{
        display: block !important;
    }
    .anticon {
        color: #fff !important;
    }
    li.main-menu__item {
        display: inline-block;
        width: 100%;
    }
    .main-menu__item--home .main-menu__item-text {
        padding: 10px;
    }
    .bm-cross-button {
        display: none;
    }
    .main-menu__item-text {
        .anticon-user {
            display: block;
        }
    }
    @media @max-small {
        display: block;
    }
    display: none;
}


.bm-overlay {
    @media @max-small {
        display: block !important;
    }
}

/* Align the burger button to the left in RTL */
.rightToLeft .bm-burger-button {
    right: auto;
    left: 22px;
}


.rightToLeft .bm-menu {
    direction: rtl; /* Adjusted for RTL */
}


.bm-burger-button {
    position: fixed;
    width: 24px;
    height: 17px;
    right: 22px;
    top: 25px;
    display: none;
    @media @max-small {
        display: block;
    }
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    display: none;
  }
