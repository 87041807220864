.custom-car {
    flex: 1;
    background: rgb(255, 255, 255);
    min-width: 235px;
    transition: background 0.3s ease-in-out;
    //justify-content: left !important;
    border-radius: 8px !important;
    margin: 4px !important;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;

    &:hover {
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
        border: 1px solid #005093;
        border-radius: 8px;
    }

    &--selected {
        background: rgb(251, 250, 255);
        border: 2px solid #005093 !important;
        border-radius: 8px !important;
    }

    @media screen and (max-width: 767px) {
        padding: 10px 24px;
    }

    &__controls {
        padding: 15px;
        .anticon svg {
            .ant-layout-ltr & {
                margin-right: 0;
            }
            .ant-layout-rtl & {
                margin-left: 0;
            }
        }
    }

    &__choose-list {
        display: flex;
        flex-wrap: wrap;
    }

    &__header {
        display: flex;

        @media screen and (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    &__photo {
        max-width: 140px;
        order: 1;


        img {
            max-width: 140%;
            border-radius: 4px;
            padding: 2px;
            margin: 2px;
        }

        span{
            width: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &__info {
        flex: 1;
        .ant-layout-ltr & {
            text-align: left;
        }
        .ant-layout-rtl & {
            text-align: right;
        }

        @media screen and (max-width: 767px) {
            display: flex;
        }
    }

    &__title {
        font-size: 16px;
        color: #025ba7;
        margin-bottom: 10px;
        font-weight: 700;
        font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif !important;
      
        @media screen and (max-width: 767px) {
          flex: 1;
        }
      
        // Add RTL-specific rule:
        .ant-layout-rtl & {
          text-align: right;
        }
      }

    &__price-small {
        font-size: 14px;
        color: #878787;
    }

    &__price-big {
        font-weight: bold;
        font-size: 17px;
    }

    &__price {
        font-weight: bold;
        font-size: 17px;

    

        @media screen and (max-width: 767px) {
            .ant-layout-ltr & {
                margin-left: 10px;
            }
            .ant-layout-rtl & {
                margin-right: 10px;
            }
        }

        span {
            display: block;
            white-space: nowrap;
       }
    }

    &__select {
        display: flex;
        align-items: center;
    }

    &__counter {
        flex: 1;
        text-align: left;
        margin-bottom: 10px;

        // Add RTL-specific rule:
        .ant-layout-rtl & {
            text-align: right;
          }
    }

    &__choose-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__choose-title-price{
        display: flex;
        align-items: center;
        span {
            .ant-layout-ltr & {
                margin-right: 5px;
            }
            .ant-layout-rtl & {
                margin-left: 5px;
            }
        }
    }

    &__choose-title-price-label {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &__choose-title-text {
        flex: 1;
    }

}

