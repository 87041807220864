/* Global style override for all input elements */

.imageWrapper {
    position: relative;
    width: 100%;
    /* You can set a max-width if needed */
    max-width: 311px;
}

.responsiveImage {
    object-fit: cover; 
    width: 100%;
    height: 100%;
}

.ant-input,
.ant-select-selector,
.ant-picker-input input,
.ant-checkbox-wrapper,
.ant-radio-wrapper,
.ant-checkbox + span,
.ant-radio + span,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector,
.ant-picker,
.ant-input-number-input {
    font-size: 16px !important; 
}

.ant-select-auto-complete .ant-select-selector input {
    font-size: 16px !important;

}

.ant-select-dropdown .ant-select-item-option-content,
.ant-select-dropdown .ant-select-item {
    font-size: 16px !important;
}

/* Include specific overrides for picker dropdown items */
.ant-select-dropdown .ant-select-item-option-content,
.ant-select-dropdown .ant-select-item,
.ant-picker-dropdown .ant-picker-time-panel-column li,
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-date-panel,
.ant-picker-dropdown .ant-picker-cells .ant-picker-cell-inner {
    font-size: 16px !important;
}

/* Increase the size of the time cells and spacing on mobile */
@media only screen and (max-width: 767px) {
    .mobile-timepicker-popup .ant-picker-time-panel-cell {
      min-height: 32px;  /* Makes each cell taller */
    }

    .ant-picker-dropdown .ant-picker-time-panel-column li {
        font-size: 17px !important;   /* Increase font size of each time option */
      }

  }

  @media only screen and (max-width: 767px) {
    .mobile-timepicker-popup .ant-picker-time-panel {
      pointer-events: auto;
    }
  }

  /* Desktop (default): buttons side by side with moderate spacing */
.button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    gap: 10px; 
  }
  
  /* Mobile: stack buttons vertically and add more margin */
  @media screen and (max-width: 767px) {
    .button-row {
      flex-direction: column;
      gap: 12px; /* Increase vertical spacing */
    }
  }


/* Ensure consistency in form item labels and descriptions */
.ant-form-item-label > label,
.ant-form-item-explain,
.ant-form-item-extra {
    font-size: 16px !important;
}

/* Global style override for Ant Design tabs */
.ant-tabs-tab,
.ant-tabs-tab-icon,
.ant-tabs-nav-wrap,
.ant-tabs-nav-list,
.ant-tabs-tab-btn {
    font-size: 17px !important;
    color: #002943;

    @media @max-small {
        font-size: 15px !important;;
    }
}




.full-with {
	.ant-select {
		width: 100% !important;
	}
}

.tabs-order-link {
    font-size: 17px !important;
    padding: 4px 0 0 4px;
    
    .anticon {
        margin-right: 2px;
    }

}

.order-form {
    margin: 10px 0 0;
    color: #555;
    font-size: 16px; 

    .ant-input,
    .ant-select-selector,
    .ant-picker-input input,
    .ant-checkbox-wrapper,
    .ant-radio-wrapper,
    .ant-select-item-option-content,
    .ant-select-item
    .ant-select-selection-search,
    .ant-form-item-control,
    .ant-form-item-control-input-content,
    .ant-form-item-label {
        font-size: 16px !important;
    }

    .ant-picker input,
    .ant-input-number input {
        font-size: 16px !important; 
    }

    .checkbox-gdpr {

        margin: -20px 0 20px 0;

        .ant-checkbox-wrapper {
            display: inline-flex;

            .ant-layout-ltr & {
                text-align: left;
            }
            .ant-layout-rtl & {
                text-align: right;
            }

            .ant-checkbox {
                height: 16px;
            }
        }

        @media @max-small {
            margin-top: 0;
        }

        .ant-layout-ltr & {
            text-align: right;
        }
        .ant-layout-rtl & {
            text-align: left;
        }
    }

    .ant-switch {
        margin-top: -4px !important;
    }

    .ant-legacy-form-item {
        margin-bottom: 10px;
    }

    .order-form-contact {
        .ant-legacy-form-item {
            margin-bottom: 10px;
        }
    }

    @media @max-small {

        .ant-legacy-form-item-label {
            margin-bottom: 0;
            padding-bottom: 0;
            font-size: 16px;
        }

        .ant-legacy-form-item {
            margin-bottom: 10px;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-legacy-form-item-label {
        font-size: 16px;

        .ant-layout-ltr & {
            text-align: left;
        }
        .ant-layout-rtl & {
            text-align: right;
        }
    }

    .pick-up-date .ant-legacy-form-item-label {

        svg {
            .ant-layout-ltr & {
                margin-right: 8px;
            }
            .ant-layout-rtl & {
                margin-right: 0;
            }
        }
    }


    &__step2 {
        .ant-form-item-control-input-content{
            .anticon {
                .ant-layout-ltr & {
                    padding-right: 8px;
                }
                .ant-layout-rtl & {
                    padding-left: 8px;
                }
            }
        }

        .anticon-phone{
            .ant-layout-ltr & {
                margin-left: 0 !important;
            }
            .ant-layout-rtl & {
                margin-right: 0 !important;
            }
        }
    }


    .white-space-nowrap {
        span {
          font-size: 16px;
        }
      
        .ant-switch {
          .ant-layout-ltr & {
            margin-right: 10px;
          }
      
          .ant-layout-rtl & {
            margin-left: 10px;
          }
          margin-top: -4px !important;
        }
      }
      

    .ant-avatar > img {
        height: auto;
    }

    @media screen and (max-width: 639px) {
        .ant-divider-inner-text {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    @media (max-width: 576px) {
        .small-screen-spacer {
          margin-bottom: 10px;
        }
      }

    .ant-form-item-label,
    .pick-up-date .ant-legacy-form-item-label {
        // max-width: 200px;
        font-size: 16px;

        .ant-layout-ltr & {
            text-align: left;
        }
        .ant-layout-rtl & {
            text-align: right;
        }

        i {
            font-size: 18px;
            vertical-align: middle;
            display: inline-block;
            line-height: 14px;

            .ant-layout-ltr & {
                margin-right: 8px;
            }
            .ant-layout-rtl & {
                margin-left: 8px;
            }

            @media @max-small {
                font-size: 16px;
            }
        }

        .anticon-question-circle-o {
            font-size: 15px;
        }
    }

    h2 {
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: #041d5e;

        @media @max-small {
            font-size: 17px;
        }

        .anticon-car {
            .ant-layout-ltr & {
                margin-right: 8px;
            }
            .ant-layout-rtl & {
                margin-left: 8px;
            }
        }

        .anticon-question-circle-o {
            font-size: 18px;
            display: inline-block;
            margin-top: 2px;
            vertical-align: middle;
        }

        @media @max-small {
            display: block;

            .ant-btn {
                vertical-align: middle;
            }

            .anticon-question-circle-o {
                margin-top: 0;
            }
        }
    }

    .anticon-close {
        .ant-layout-ltr & {
            margin-left: 0;
        }
        .ant-layout-rtl & {
            margin-right: 0;
        }
    }

    .ant-switch-inner i {
        margin: 0;
    }

    .ant-switch-inner svg,
    .ant-input-number-handler-wrap  svg{
            font-size: 12px !important;
    }

    .ant-list-bordered {
        border-color: #e8e8e8;
    }

    @media @max-medium {
        margin-top: 20px;
    }
}

.order-form__price-label {
    font-size: 11px;
    color: rgba(0,0,0,.65);
    font-weight: normal;

    @media screen and (max-width: 767px) {
        display: none; 
    }

}

.order-from-price-small {
    display: block;
    color: #808080;
    font-size: 12px;
}

@media screen and (max-width: 767px) {
    .order-from-price-small {
        display: none;
    }
}

.order-form__top-car {
    cursor: pointer;
    text-align: center;
    height: 100%;

    .ant-card-head-title {
        padding-top: 14px;
    }

    &.is-active {
        border-color: #40a9ff;
    }

    @media @max-medium {
        padding: 15px;
    }
}

.order-form__top-car-wrapper {
    @media @max-small {
        margin-bottom: 10px;
    }
}

.order-form__top-car-title {
    font-size: 16px;
    color: #025ba7;
    margin-bottom: 10px;
    font-weight: 700;
    min-width: 150px;
    font-family: "Montserrat", "Open Sans", Helvetica,Arial,sans-serif !important;
}

.order-form__top-car-option {
    font-size: 16px;
    margin-bottom: 10px;
}


.order-form__top-car-info {
    flex: 1;
    font-size: 13px;
    padding: 12px;

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    i {
        .ant-layout-ltr & {
            margin-right: 15px;
        }
        .ant-layout-rtl & {
            margin-left: 15px;
        }
    }
}

.order-form__top-car-info_tags {
    flex: 1;

    @media screen and (max-width: 767px) {
        display: none;
        margin-bottom: 0;
    }

    i {
        .ant-layout-ltr & {
            margin-right: 15px;
        }
        .ant-layout-rtl & {
            margin-left: 15px;
        }
    }
}

.order-form__top-car-info-label {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.order-form__top-car-price {
    color: rgb(62, 62, 62);
    margin-bottom: 0;
    padding-left: 14px;
    padding-right: 20px;
    font-size: 19px;
    font-weight: 600;
}

.order-form__top-car-image {
    display: block;
    margin: 10px auto 0;
    height: 100px;
}

.kids {

    h2 {
        svg {
            font-size: 18px;
        }
    }
}

.order-form__card--summary {
    .ant-form-item {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 5px;
            padding-bottom: 0;
        }
    }
}

.order-form__custom-car:hover {
    background-color: rgb(244, 242, 253)
}

.order-form__custom-car {
    cursor: pointer;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
    //justify-content: left !important;
    margin-bottom: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 

    

     .slick-dots {
         li {
             width: 8px;
             height: 8px;
             border-radius: 20px;
             button {
                width: 8px !important;
                height: 8px !important;
                border-radius: 20px !important;
             }
             &.slick-active {
                button {
                    background: #1890FA;
                }
             }
         }
     }

    .ant-list-item-content {
        align-items: center;
    }

    .order-form__top-car-name {
        flex: 1;
        
    }

    .order-form__top-car-info {

        
        .ant-layout-ltr & {
            padding-right: 30px;
        }
        .ant-layout-rtl & {
            padding-left: 30px;
        }

        div{
            display: flex;
            align-items: center;

            span:nth-child(2) {
               margin-left: 5px;
               margin-right: 5px;
            }
        }
    }

    .order-form__top-car-title {
        margin-bottom: 0;
        margin-top: 0;
        font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif !important;
    }

    .order-form__top-car-info {
        margin-bottom: 0;
        .ant-layout-ltr & {
            margin-left: 10px;
        }
        .ant-layout-rtl & {
            margin-right: 10px;
        }
    }


    @media @max-medium {
        .ant-list-item-content {
            flex-wrap: wrap;
        }


        .order-form__top-car-info {
            flex: 0 0 100%;
            text-align: left;
            margin-bottom: 10px;

            .ant-layout-ltr & {
                margin-left: 0;
            }
            .ant-layout-rtl & {
                margin-right: 0;
            }
        }

        .order-form__top-car-price {
            .ant-layout-ltr & {
                text-align: left;
            }
            .ant-layout-rtl & {
                text-align: right;
            }
        }
    }
    
 }

 .custom-avatar {
    width: 120px;
    height: 90px;
    line-height: 90px;
    font-size: 60px; 
    
}

.custom-car__info {
    padding: 0 18px; 
}


.custom-car__header {
    padding-top: 18px; 
}

 .order-form__custom-car-image {
    width: 130px;

    .ant-layout-ltr & {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
    .ant-layout-rtl & {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    img {
        max-height: 100px;
        max-width: 100%;
        border-radius: 4px;
    }

    .ant-avatar {
        display: flex;
        
    }



}

.order-form__route-stats {
    .ant-card-body {
        padding: 20px !important;
    }
}

.order-form__route-place {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    .ant-card-body {
        padding: 20px !important;
    }
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    top: 50%;
    transform: translateY(-50%);
}

.order-form__payment {


    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding-bottom: 15px;

    @media @max-small {
        padding: 8px 10px !important;
    }

}

.order-form .ant-legacy-form-item {
    align-items: center;
}

.order-form__cardCars {
    background-color: #f9f9f9; /* Very light grey */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid #e8e8e8; /* Light border */
    margin-bottom: 20px !important;
}



.order-form__card {
    margin-bottom: 38px !important;

    .ant-card-body {

        @media @max-small {
            padding: 15px !important;
        }

    }
    

    &.custom-card {
        background-color: #f9f9f9; /* Very light grey */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        border: 1px solid #e8e8e8; /* Light border */
    }

    &.custom-card-summary {
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        border: 1px solid #e8e8e8; /* Light border */
    }

    &--cars {
        .ant-card-body {
            padding: 0 1px 1px 0 !important;
        }


    }
}

.order-form__payment-content {
    margin-bottom: 15px;
    margin-top: 10px;
}

.order-form__notes-content {
    margin-bottom: 5px; 
}

.order-form__payment {

    .ant-list-item-action {

        @media @max-small {

            width: 100%;
            margin: 0 !important;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 23px;

            .ant-layout-rtl &,
            .ant-modal-rtl & {
                padding-right: 23px;
                padding-left: 0;
            }

            li {
                display: block;
                width: 100%;

                > .ant-select {
                    width: 100% !important;
                }
            }

        }
    }

    .ant-select-selection__clear {
        .ant-layout-ltr & {
            right: 55px !important;
        }
        .ant-layout-rtl & {
            left: 55px !important;
        }
    }


}

.order-form__card--auto-width {
    .ant-form-item-label {
        max-width: unset;
    }
}

.order-form__card--last {
    @media @max-medium {
        margin-bottom: 10px !important;
    }
}

.order-form__checkbox {

    .ant-col {
        width: 100% !important;
        max-width: 100% !important;
        flex: 1 !important
    }


}




.order-form__checkbox-return-modify {

    padding-top: 13px;
    margin-inline-start: 20px;

    @media @max-small {
        display: none;
    }

}

.order-form__stats {
    
    @media @max-small {
        display: none;
    }

}



.order-form__back-route {
    margin-bottom: 0 !important;

}

.order-form__payment {
    margin-bottom: 0;

    &.ant-list-item {
        justify-content: flex-start;
    }

    .ant-select-selection-item {
        .ant-layout-ltr & {
            text-align: left;
        }
        .ant-layout-rtl & {
            text-align: right;
        }
    }

    .ant-list-item-content {
        flex-wrap: nowrap;
    }
}

.order-form__payment-help {
    flex: 1;
    padding: 0 5px;


    @media @max-small {
        display: none;
    }

    //@media @max-small {
    //    flex: auto;

    //    .ant-btn {
    //        margin: 0 !important;
    //        padding: 0 !important;
    //    }
    //}



}


.order-form__payment-icons {

    .ant-layout-ltr & {
        margin-left: 10px;
    }
    .ant-layout-rtl & {
        margin-right: 10px;
    }

    svg {
        vertical-align: middle;
    }

    svg + svg {
        .ant-layout-ltr & {
            margin-left: 5px;
        }
        .ant-layout-rtl & {
            margin-right: 5px;
        }
    }

    // @media @max-small {
    //     display: none;
    // }
}

// For modals
.ant-modal-wrap {

    .ant-select-selection-item {
        text-align: left;
    }

    .order-form__payment-icons {

        margin-left: 10px;

        svg {
            vertical-align: middle;
        }

        svg + svg {

            margin-left: 5px;
        }

    }

    &.ant-modal-wrap-rtl {

        .ant-select-selection-item {
            text-align: right;
        }

        .order-form__payment-icons {

            margin-right: 10px;

            svg {
                vertical-align: middle;
            }

            svg + svg {

                margin-right: 5px;
            }

        }

    }

}
// END - for modals

.order-form__payment-radio {
    .ant-layout-ltr & {
        margin-right: 10px;
    }
    .ant-layout-rtl & {
        margin-left: 10px;
    }

    @media @max-small {
        margin: 0 !important;
    }
}

.order-form__payment-text {
    font-size: 16px;

    .ant-layout-ltr & {
        text-align: left;
    }
    .ant-layout-rtl & {
        text-align: right;
    }

    @media @max-small {
        font-size: 12px;
    }

}

.order-form__other-payments {
    margin-top: 20px;
    text-align: center;
}

.order-form__total {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    font-size: 17px;

    @media @max-small {
        font-size: 16px;
    };
}

.order-form__total-text {
    margin-bottom: 0;
    font-weight: 500;
    color:#194265;
    flex: 1;
    min-width: 150px;
}

.order-form__total-price {
    margin-bottom: 0;
    color:#194265;
    white-space: nowrap;
    display: flex;
    span:nth-of-type(1) {
        .ant-layout-ltr & {
            margin-left: 5px;
        }
        .ant-layout-rtl & {
            margin-right: 5px;
        }
    }
}

.order-form__summary {
    text-align: unset;
    .ant-form-item {
        margin-bottom: 10px;
    }


    .ant-form-item-control {
        font-size: 16px;

        i {
            .ant-layout-ltr & {
                margin-right: 8px;
            }
            .ant-layout-rtl & {
                margin-left: 8px;
            }
        }
    }

    .order-form__card {
        margin-bottom: 20px !important; 
    
        .ant-tag {
            margin-top: 10px;
        }
    
        .ant-form-item-label {
            font-weight: bold; 
        }
    
        .non-bold-label .ant-form-item-label {
            font-weight: normal !important;
        }
    
        .highlighted-value {
            font-weight: 600;
        }
    }
    
    

    .ant-form-item-children {
        padding-top: 5px;
        line-height: 25px;
        display: block;
    }

    @media @max-medium {
        .ant-form-item-label {
            padding-bottom: 0;
        }

        .ant-form-item {
            margin-bottom: 15px;
        }
    }
}


.summary-headline {
  padding-top: 10px;
}

.summary-headline2 {
    padding-top: 0px;
  }

.order-form__summary-list {
    li {
        padding: 5px;
        font-size: 18px;

        a {
            color: #555;
        }
    }

    @media @max-small {
        .ant-layout-ltr & {
            padding-left: 20px;
        }
        .ant-layout-rtl & {
            padding-right: 20px;
        }
    }
}

.order-form__summary-footer {
    justify-content: flex-end;
    
    @media @max-medium {
        
        .ant-btn {
            width: 100%;
            margin-bottom: 10px;
            margin-top: 20px;
        }
    }
}

.order-form__summary-footer-back {
    @media @max-medium {
        margin-bottom: 10px;
    }
}

.order-form__summary-footer .ant-btn {
    @media @max-medium {
        margin-bottom: 10px;
    }
}


.order-form__summary-footer-confirm {
    @media @max-medium {
        width: 100%;

        &.ant-btn-primary {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .gdpr-component {
      margin-top: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
  }

.order-form__popover {
    //max-width: 400px;
    background-color: #fff9f9fa;
    font-size: 15px;
    
}

.order-form__hide_mobile {
    @media @max-small {
        display: none;
    }
}



.ant-popover-inner {
    padding: 6px !important;
    border-radius: 0px;
    background-color: #fff9f9fa !important;
    font-size: 15px;
    border: 1px solid rgb(125, 193, 248);
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}



.order-form__custom-add-button {
    .ant-layout-ltr & {
        margin-left: 20px;
    }
    .ant-layout-rtl & {
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        display: block;
        margin: 10px auto 0;
    }
}


@media screen and (max-width: 767px) { 
    .search-input_autocomplete .search-btn {
        display: none !important;
    }
}



.order-form__steps {
    margin: 20px 0 30px;
    position: relative;

    .ant-steps-item-title {
        white-space: nowrap;
        font-size: 13px;
    }

    @media @max-small {

        display: none; // hide on mobile

        margin: 20px 0 20px;

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 3px;
            right: 3px;
            height: 2px;
            background: #ccc;
            z-index: 10;
        }

        .ant-steps-item {

            position: relative;
            z-index: 20;

            &.ant-steps-item-wait {

                .ant-steps-icon-dot {
                    background: #ccc !important;
                }

            }

            &.ant-steps-item-process {

                .ant-steps-icon-dot {
                    width: 11px;
                    height: 11px;
                }

            }

            &:first-child {

                .ant-steps-item-tail {
                    display: none;

                    .ant-layout-ltr & {
                        margin-left: 3px;
                    }
                    .ant-layout-rtl & {
                        margin-right: 3px;
                    }
                }

                .ant-steps-item-icon {
                    .ant-layout-ltr & {
                        margin-left: 0;
                    }
                    .ant-layout-rtl & {
                        margin-right: 0;
                    }
                    margin-top: 0px;
                    float: none;
                }

                .ant-steps-item-content {
                    .ant-layout-ltr & {
                        text-align: left;
                    }
                    .ant-layout-rtl & {
                        text-align: right;
                    }
                }

            }

            &:nth-child(2) {

                .ant-steps-item-tail {
                    display: none;

                    .ant-layout-ltr & {
                        margin-left: 3px;
                    }
                    .ant-layout-rtl & {
                        margin-right: 3px;
                    }
                }

                .ant-steps-item-icon {
                    margin-top: 0px;
                    float: none;

                    .ant-layout-ltr & {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                    .ant-layout-rtl & {
                        margin-right: 15px;
                        margin-left: 0;
                    }
                }

                .ant-steps-item-content {
                    // max-width: 60px;

                    .ant-layout-ltr & {
                        margin-left: -10px;
                    }
                    .ant-layout-rtl & {
                        margin-right: -10px;
                    }

                }

            }

            &:last-child {
                .ant-steps-item-tail {
                    display: none;
            
                    .ant-layout-ltr & {
                        margin-left: 3px;
                    }
                    .ant-layout-rtl & {
                        margin-right: 3px;
                    }
                }
            
                .ant-steps-item-icon {
                    margin-left: 0;
                    margin-right: 0;
                }
            
                .ant-steps-item-content {
                    text-align: right !important;
            
                    .ant-steps-item-title {
                        padding-right: 0px;
                    }
            
                    .ant-layout-ltr & {
                        text-align: left;
                    }
                    .ant-layout-rtl & {
                        text-align: right;
                    }
                }
                .ant-steps-item-icon {
                    margin-top: 0px;
                    float: none;
                }
            
            }
        }

        .ant-steps-item-title {
            font-size: 12px;
            // max-width: 60px;
        }

        .ant-steps-item-content {
            width: 100%;
        }

    }
    .ant-steps-vertical {
        flex-direction: unset;
    }
}

.ant-btn-background-ghost.ant-btn-primary {
    .ant-layout-ltr & {
        padding-left: 8px;
    }
    .ant-layout-rtl & {
        padding-right: 8px;
    }

    &.ant-btn-rtl {
        margin-left: 8px;
    }
}

.order-form__back-route {

    svg{
        font-size: 14px !important;
    }

    .ant-btn {
        .ant-layout-rtl & {
            margin-left: 8px;
        }
    }
}

.order-form-notes {
    .ant-legacy-form-item-control-wrapper {
        width: 100%;
    }

    .order-form-notes-title {
        display: inline-block;
        font-size: 17px !important;
    }

}

.order-form-vehicles {


    display: inline-block;

    .ant-layout-rtl & {
        margin-left: 8px;
    }

    .ant-layout-ltr & {
        margin-right: 8px;
    }


}


.pick-up-date h2 svg,
.order-form__extras svg,
.order-form-notes .ant-btn svg{
    font-size: 18px;
}

.order-form-notes .anticon-edit{
    .ant-layout-ltr & {
        margin-right: 8px;
    }
    .ant-layout-rtl & {
        margin-left: 8px;
    }
}

.ant-select-dropdown {

    .ant-select-item-option-content {
        div {
            justify-content: flex-start !important;
            align-items: center !important;
            font-size: 16px !important;
        }

        span.anticon {
            margin-right: 10px;
        }

    }

    &.ant-select-dropdown-rtl {
        .ant-select-item-option-content {
            span.anticon {
                margin-left: 10px;
                margin-right: 0;
                font-size: 16px !important;
            }
        }
    }
}


.order-form__extras {
    display: flex;
    align-items: center; // Center the items vertically
    justify-content: space-between; // Ensure spacing between title and button
    margin-bottom: 20px;

    .order-form__extras-title {
        margin-bottom: 0;
        font-size: 17px !important;
    }
}

.order-form__extras-button {
    .ant-layout-ltr & {
        margin-left: 10px;
    }
    .ant-layout-rtl & {
        margin-right: 10px;
    }

    svg {
        font-size: 14px !important;
    }

    @media (max-width: 768px) { // Mobile screens
        .extra-label {
            display: none; // Hide the text labels on mobile, only show the icon
        }

        .ant-btn {
            width: auto; // Ensure the button does not take full width on mobile
            display: inline-flex;
            justify-content: center; // Center the button content
        }
    }
}


.order-form__table-basket {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 13px;

    @media @max-medium {
        min-width: 440px;
    }
}

.order-form__table-basket-add {
    margin-top: 20px;

    @media @max-medium {
        min-width: 340px;
    }
}

.order-form__price {
    background-color: #f9f9f9;
    padding: 0 22px 22px 22px !important;
    margin: 0 0 0px !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid #e8e8e8; /* Light border */
}

.order-form__price-summary {
    background-color: #fbfbfb;
    padding: 0 22px 22px 22px !important;
    margin: 0 -22px -22px;
    //border: 1px solid black;
    border-radius: 5px;

    @media (max-width: 576px) {
        padding: 0 15px 15px 15px !important; /* Reduce padding */
        margin: 0 -12px -12px; /* Remove negative margin */
        border-width: 1px; /* Adjust border width if needed */
    }
}


.order-form__price .ant-list .ant-list-item {
    padding: 3px 0;
}

.order-form__card .ant-page-header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #E8E8E6;

    .ant-layout-ltr & {
        padding-left: 0;
        padding-right: 0 !important;
    }
    .ant-layout-rtl & {
        padding-right: 0;
        padding-left: 0 !important;
    }

    .ant-page-header-title-view-extra {
        .ant-layout-ltr & {
            right: 0;
        }
        .ant-layout-rtl & {
            left: 0;
        }
    }
}

.input-count {
    display: flex;
    // align-items: center;

    .ant-input-number {
        flex: 1;
        @media @max-small {
            .ant-input-number-handler-wrap {
                opacity: 1;
                width: 100%;
                height: auto;
            }
        }
    }

    .ant-btn-group {
        .ant-layout-ltr & {
            margin-left: 10px;
            text-align: left;
        }
        .ant-layout-rtl & {
            margin-right: 10px;
            text-align: right;
        }

        .ant-btn-icon-only {

            .ant-layout-ltr & {
                padding-left: 0 !important;
            }
            .ant-layout-rtl & {
                padding-right: 0 !important;
            }
        }

        .ant-btn:first-child {

            .ant-layout-ltr & {
                margin-left: 10px;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
            .ant-layout-rtl & {
                margin-right: 10px;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
        }

        .ant-btn {
            .ant-layout-ltr & {
                margin-left: 5px;
            }
            .ant-layout-rtl & {
                margin-right: 5px;
            }
        }
    }
}


.order-form {
    font-size: 16px;

    .ant-form-item-label {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    @media @max-medium {
        .ant-form-item-label {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
    }
}

.search-btn {
    border: 0 !important;
    background: transparent !important;

    .ant-layout-ltr & {
        border-left: 1px solid #d9d9d9 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .ant-layout-rtl & {
        border-right: 1px solid #d9d9d9 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    @media screen and (max-width: @max-small) {
            display: none !important; 
    }
}

.search-input_autocomplete {
    @media screen and (max-width: @max-small) {
            display: none !important; 
    }
}


.order-form__row {
    display: flex;
    align-items: center;
}

.order-form__back-route-details {
    margin-top: 10px;
    // svg {
    //     font-size: 18px !important;
    // }
}

.ant-steps-item-tail:after {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: calc(100% - 2px)!important;
}

.time-duration{

    .ant-layout-ltr & {
        margin-left: 5px;
    }
    .ant-layout-rtl & {
        margin-right: 5px;
    }
}

.filterWrapper{
    width: 100%;
    .ant-picker-range-separator{
        .ant-layout-ltr & {
            padding-right: 30px;
        }
        .ant-layout-rtl & {
            padding-left: 30px;
        }
    }
}

.order-form-contact .ant-btn-background-ghost.ant-btn-primary{

        margin: 5px;

    .ant-layout-ltr & {
        padding-left: 8px;
    }
    .ant-layout-rtl & {
        padding-right: 8px;
    }


}

.ant-form-item-explain {
    min-height: 0 !important;
}

.ant-form-item-label>label:after,
.ant-legacy-form-item-label > label::after  {
    content: " " !important;
}

.pick-up-date,
.order-form-contact{

    .ant-form-item-has-error,
    .ant-form-item,
    .ant-legacy-form-item{
        .anticon {
            .ant-layout-ltr & {
                padding-right: 0;
            }
            .ant-layout-rtl & {
                padding-left: 0;
            }
        }

    }
}

.conditionsCheckbox{
    padding-left: 24px;
    padding-bottom:24px;
}

.location {
    display: flex;
    justify-content: space-between;

    &__side {

        font-size: 13px;
        color: rgb(152, 150, 150);
        white-space: normal;
        margin-inline-start: 10px;
    }

    @media @max-medium {
        .location__content {
            white-space: normal;
        }

        .location__side {
            display: block;
            margin: 0 !important;
        }
    }
}

.order-from-passengers {
    font-weight: 500;
    font-size: 18px;

    @media @max-small {
        font-size: 16px;
        font-weight: 600;
    }
}


.orderFormTime{
    width: 85% !important;
    float: left;
    margin-right: 10px;
}
.orderFormDisplayTime{
    padding-left: 5px; padding-top: 4px;
    color: #7a7edf;
}


.ant-row-rtl {
    .flag {
        right: 5px;
        .arrow {
            right: 20px;
        }
    }
    
    .react-tel-input{
        .form-control {
            padding-right: 54px;
        }
    } 
}

.whole-form-padding {
    padding: 18px 18px; /* Default padding */

    @media @max-small {
        padding: 4px 14px;
    }
}


// Default (LTR) offset styling for badges
.custom-badge-offset {
    .ant-badge-count,
    .ant-badge-status-dot {
      transform: translate(0px, 0px);
      height: 21px !important;
    }
  }
  
