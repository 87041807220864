.place {
    display: flex;
    align-items: center;

    &-thumb {
        .ant-layout-ltr & {
            margin-right: 20px;
        }
        .ant-layout-rtl & {
            margin-left: 20px;
        }
    }

    &-desc {
        flex: 1;

        p {
            margin: 0;
        }
    }
}