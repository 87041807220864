body {
    margin: 0;
    font-size: 16px;
    transition: padding-right 0.3s ease-out;
}
a {
    color: #c0392b;
}
ul.main-menu {
    margin-top: 0;
}

.no-scroll {
    overflow: hidden;
    position: relative; /* Keeps the layout stable */
    width: 100%; /* Ensures the width is not affected */
}

.fixed-position {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll; 
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner {
    background-color: #005093 !important;
    border-color: #005093 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #005093 !important;
}

.ant-checkbox-wrapper:focus-within .ant-checkbox-inner,
.ant-radio-wrapper:focus-within .ant-radio-inner {
    border-color: #005093 !important;
}

.ant-tabs-tab-active .tabs-order-link {
    color: #005093;
}
.ant-tabs-nav-wrap .ant-tabs-ink-bar {
    background: #005093;
}
.ant-tabs-tab:hover .tabs-order-link {
    color: #1a68a1;
}
.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #005093 !important;
}
.input-count__minus, .input-count__plus {
    border-radius: 2px;
    background: #005093;
}
.input-count__minus:hover, .input-count__plus:hover {
    background: #1a68a1 !important;
}


.ant-btn-primary {
    color: #fff;
    border-color: #005093;
    background: #005093;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    padding: 4.9px 15px;
    font-size: 16px;
    border-radius: 5px;
    height: auto;
}

/* Hide the "Today" button in the DatePicker footer */
.ant-picker-footer .ant-picker-now-btn {
    display: none;
}


.ant-picker-ok .ant-btn {
    padding: 2px 8px !important;  /* Adjust padding for smaller button */
    font-size: 14px !important;   /* Adjust font size */
    height: auto !important;      /* Ensure the height is adjusted */
    line-height: normal !important; /* Adjust line height */
}

.ant-btn-primary:hover {
    border-color: #1a68a1;
    background: #1a68a1 !important;
}

.ant-btn-primary.ant-btn-lg {
    font-size: 18px;
}
.ant-select-single {
    height: auto;
}
.ant-layout-ltr .header__user-menu .ant-btn-primary {
    line-height: 1.2;
    margin-left: 8px;
    margin-right: 8px;
}
.order-sidebar__subtitle {
    margin-top: 0px;
    margin-bottom: 5px;
}
.order-sidebar__box li {
    margin-bottom: 3px;
}
.order-form .ant-switch.ant-switch-checked {
    background: #005093 !important;
}


.order-form .ant-switch:hover {
    border-color: #004276; 
}

.order-form .ant-switch.ant-switch-checked:hover {
    background: #004276 !important;
}

.orders-list__profileList button.ant-btn {
    line-height: 1.4;
    height: auto;
    font-size: 16px;
    /*border-radius: 2px;*/
    padding: 4.9px 15px;
}
.orders-list__item-content .ant-tag.ant-tag-has-color {
    background: #005093 !important;
}
.orders-list__item-content .ant-tag.ant-tag-has-color {
    background: #005093 !important;
}

/* Force hide the clear button in the TimePicker */
.ant-picker-clear {
    display: none !important;
}

/* Mobile-specific adjustments for LTR */
@media screen and (max-width: 575px) {
    .am-pm span.am-pm-value {
        right: 35px;
        font-size: 14px;
        top: 72%;
    }
}

/* RTL adjustments */
.ant-layout-rtl .am-pm span.am-pm-value {
    left: 10px; /* Adjust to avoid overlap in RTL */
    right: auto;
    top: 50%;
    transform: translateY(-50%);
}

/* Mobile-specific adjustments for RTL */
@media screen and (max-width: 575px) {
    .ant-layout-rtl .am-pm span.am-pm-value {
        left: 8px;
        font-size: 14px;
        top: 75%;
    }
}


.phoneNumber label.ant-form-item-no-colon {
    display: block !important;
    font-size: 16px !important;

    @media (max-width: 767px) {
        font-size: 14px !important;
    }

    .ant-layout-rtl & {
        text-align: right;
    }
}

.phoneNumberLabel {

    @media (max-width: 576px) {
        font-size: 14px !important;
    }
}

.excursionViaLabel {
    @media (max-width: 767px) {
        display: none;
    }
}


@media screen and (max-width: 767px){
    .ant-layout-ltr .orders-list__item__header__inner .orders-list__item-data {
        margin-right: 6px !important;
    }
    .orders-list__sub-item .orders-list__item__header .orders-list__item__header__inner {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 574px){
    .ant-layout-ltr .order-form__steps .ant-steps-item:first-child .ant-steps-item-icon {
        margin-top: -1px;
    }
    .input-count .ant-input-number .ant-input-number-handler-wrap{
        width: 22px !important;
        height: 100% !important;
    }
    
}

/* Base styles for the card */
.order-card {
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    transition: border 0.3s, transform 0.3s;
    height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 108px;
    padding: 0px; 
    margin: 0px; 
  }

  .order-card-body {
    padding: 6px; 
  }
  
  .order-card.active {
    border: 2px solid #005093;
    background-color: #f7f7f8;
  }
  
  .order-card-cover {
    padding: 12px; 
    padding-top: 0;
    margin-top: 0;
  }

  .ant-card-meta-new {
    margin: -4px -18px !important;
  }
  
  .ant-card-meta-title {
    margin-top: 2px; 
    font-size: 15px !important;
    font-weight: 500 !important;
    white-space: collapse !important;
    font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif !important;
  }
  
  /* Responsive adjustments for small screens */
  @media (max-width: 576px) {
    /* Hide the icon container */
    .order-card-cover {
      display: none;
    }
  
    /* Show only the title, adjusting margin and font-size */
    .order-card-meta-title {
      margin-top: 1px;
      font-size: 14px;
    }
  
    /* Adjust card padding, margin and height to make it more compact */
    .order-card {
      padding: 1px;
      margin: 4px;
      max-height: 58px; 
    }
  }