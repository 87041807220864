.login-form {
    padding: 30px;
    background: #fff;

    input {
        width: 100% !important;
    }

    .ant-legacy-form-item-control-wrapper {
        width: 100%;
    }
}
.login-form__title {
    text-align: center;
    margin-bottom: 15px;
}

.login-form__forgot {
    .ant-layout-ltr & {
        float: right;
    }
    .ant-layout-rtl & {
        float: right;
    }
}

.login-form__footer {
    margin-bottom: 0;

    &--wrapper{
        display: flex;
        justify-content: space-between;
    }
}

.change-password-wraper{
    .ant-form-explain {
        margin-bottom: 10px;
    }
}


.login-form__button {
    width: 100%;
    margin-top: 15px;
}

.login-form-button {
    display: inline-block; 
    margin-top: 20px;
}
