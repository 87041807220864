.footer {
    background-color: #2a3136;
    color: @color-white;
    padding: 24px 18px 5px;

    a {
        color: #dddddd;

        &:hover,
        &:focus {
            color: #ffffff;
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.footer__social {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 12px;

    .ant-layout-ltr & {
        text-align: center;
    }
    .ant-layout-rtl & {
        text-align: center;
    }

    @media @max-medium {
        padding-top: 12px;

        .ant-layout-ltr & {
            text-align: center;
        }
        .ant-layout-rtl & {
            text-align: center;
        }
    }
}

.footer__social-item {
    display: inline-block;

    .ant-layout-ltr & {
        margin-right: 8px;
    }
    .ant-layout-rtl & {
        margin-left: 8px;
    }

    &:hover,
    &:focus {
        .footer__social-item-icon path {
            fill: @color-white;
        }
    }
}

.footer__social-item-icon {
    color: #919191;
    font-size: 18px;
    fill: #919191;
    vertical-align: middle;
    margin: 0 2px;

    path {
        fill: #919191;
        transition: @transition;
    }
}

.footer__copyright {
    font-size: 14px;
    color: #949494;
    text-align: center;
    padding-bottom: 30px;
}