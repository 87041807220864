.header {
  position: sticky;  // or fixed if you prefer
  top: 0;
  left: 0;
  right: 0;
  background-color: #fefefe; // make sure this is the color you want
  box-shadow: 0 3px 10px rgba(146, 146, 146, 0.1); /* Subtle shadow */
  z-index: 999;
  transition: all 0.3s ease;

  // This pseudo-element creates a full-width bottom border with shadow.
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #fcfcfc; 
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    pointer-events: none;
  }

  .ant-layout-rtl & {
    direction: rtl;
  }

  // (Optional) Add an extra shadow when scrolled:
  &.header--scrolled {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  .ant-layout-rtl & {
      direction: rtl;
  }

  &--off-canvas {

      background: rgba(@color-white, 0.97);
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);

      .header__logo {
          padding: 5px 0;

          img {
              max-height: 35px;
          }
      }

      .header__wrapper {
          min-height: 60px;
      }
  }

  &__main-menu {

    @media @max-menu {
      flex: 100%;
    }

    a {
      display: inline-block;
      white-space: nowrap;
      text-decoration: none;
      color: @color-primary;
      font-size: 16px;
      padding: 13px 15px;
      transition: all 0.3s ease;

      &:hover {
        color: darken(@color-primary, 10%);
      }
    }
  }

  &__logo {

      .ant-layout-ltr & {
          margin-right: 25px;
      }
      .ant-layout-rtl & {
          margin-left: 25px;
      }

      @media @max-medium {
          margin: 0 !important;
          max-width: 50%;
      }

      img {
          transition: @transition;
          max-height: 60px;
          max-width: 100%;
      
          @media @max-menu {
              max-height: 45px;
          }
      
          @media (max-width: 576px) { 
              max-height: 35px;
          }
      }

  }

  &__settings-menu {

      flex: 1;
      display: flex;
      justify-content: flex-end;

      @media @max-menu {
          position: absolute;
          top: 0;
          display: flex;
          align-items: center;
          height: calc(100% - 50px);

          .ant-layout-ltr & {
              right: 0;
          }
          .ant-layout-rtl & {
              left: 0;
          }
      }
      @media @max-small {
          .ant-layout-ltr & {
              right: 50px;
              top: 20px;
          }
      }

  }

  &__wrapper {

      display: flex;
      flex-flow: row wrap;
      align-items: center;
      
      min-height: 90px;
      z-index: 9999;
      background-color: @color-white;
      
      

      @media @min-menu {
        flex-wrap: nowrap;
        
    }

      @media @max-menu {
          margin: 0 -15px;
          padding: 15px 15px;
          min-height: 75px;
      }

  }

  &__user-menu {

      flex: 0 0 auto;

      .ant-btn-primary {
          background-color: @color-light;
          border-color: @color-light;

          .ant-layout-ltr & {
              margin-left: 10px;
          }
          .ant-layout-rtl & {
              margin-right: 10px;
          }

          &:hover, &:focus {
              background-color: lighten(@color-light, 10);
              border-color: lighten(@color-light, 10);
          }
      }

      @media @max-menu {
          display: none;
      }

  }

}
