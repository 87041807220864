.orders-list {
    
    @media @max-small {
        margin-top: 10px;
    }

    &__profileList {
        padding: 24px;
        min-height: 280px;
        background-color: #ffffff;

        @media @max-medium {
            padding: 10px;
        }
    }

    @media @max-small {
        .ant-pagination {
            .ant-pagination-total-text {
                width: 100%;
                margin: 0 0 10px;
            }
        }
    }

    &__space {
        .ant-layout-ltr & {
            margin-right: 10px;
        }
        .ant-layout-rtl & {
            padding-left: 10px;
        }
    }

}

.orders-list__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
    height: 32px;

    .ant-layout-ltr & {
        padding-left: 15px;
    }
    .ant-layout-rtl & {
        padding-right: 15px;
    }

    @media @max-small {
        .ant-layout-ltr & {
            padding-left: 10px;
        }
        .ant-layout-rtl & {
            padding-right: 10px;
        }
    }
}

.orders-list__item {
    margin-top: 10px !important;

    &--storno {
        .ant-card {
            background-color: #F2F2F2;
            border-color: darken(#F2F2F2,7%);
            border-left: 4px solid #120230; 
            .orders-list__item__header__inner,
            .ant-card-head {
                border-color: darken(#F2F2F2,7%);
            }
            .ant-divider {
                background-color: darken(#F2F2F2,7%);
            }
        }
    }

    &--confirmed {
        .ant-card {
            background-color: #f9f7f1;
            border-color: darken(#f7f7ed,7%);
            border-left: 4px solid #1890ff; 
            .orders-list__item__header__inner,
            .ant-card-head {
                border-color: darken(#f7f7ed,7%);
            }
            .ant-divider {
                background-color: darken(#f7f7ed,7%);
            }
        }
    }

    &--unconfirmed {
        .ant-card {
            background-color: #fff5ed;
            border-color: darken(#fff5ed,7%);
            border-left: 4px solid #b2590b; 
            .orders-list__item__header__inner,
            .ant-card-head {
                border-color: darken(#fff5ed,7%);
            }
            .ant-divider {
                background-color: darken(#fff5ed,7%);
            }
        }
    }

    &--main {
        .ant-card-body {
            display: none !important;
        }
    }

    &.orders-list__sub-item {
        background: #f7f7f7;
        position: relative;

        .ant-layout-ltr & {
            margin-left: 50px;
        }
        .ant-layout-rtl & {
            margin-right: 50px;
        }

        .orders-list__item__header {
            margin-bottom: 0;
        }

        @media @max-small {
            .orders-list__item-row.orderlistcst div {
                justify-content: flex-start !important;
                text-align: left !important;
                max-width: 70%;
            }
            .orders-list__item-row.orderlistcst div .orders-list__item-data {
           
                justify-content: flex-start !important;
            }

            .ant-layout-ltr & {
                margin-left: 15px;
            }
            .ant-layout-rtl & {
                margin-right: 15px;
            }

            .ant-card-head-wrapper {
                display: block;
            }
        }

        .orders-list__item__id--order {
            display: none;

            & + .ant-divider {
                display: none;
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
        }

        &:before {

            top: 0;
            bottom: 50%;
            width: 1px;
            background: #e8e8e8;

            .ant-layout-ltr & {
                left: -50px;
            }
            .ant-layout-rtl & {
                right: -50px;
            }

            @media @max-small {
                .ant-layout-ltr & {
                    left: -20px;
                }
                .ant-layout-rtl & {
                    right: -20px;
                }
            }
        }

        &:after {
            left: -50px;
            width: 40px;
            bottom: 50%;
            height: 1px;
            background: #e8e8e8;

            .ant-layout-ltr & {
                left: -50px;
            }
            .ant-layout-rtl & {
                right: -50px;
            }

            @media @max-small {
                width: 15px;

                .ant-layout-ltr & {
                    left: -20px;
                }
                .ant-layout-rtl & {
                    right: -20px;
                }
            }
        }
    }

    .ant-card-head-wrapper {
        flex-wrap: wrap;

        .ant-card-head-title {
            overflow: visible;
        }
    }
    .ant-card-body {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 7px 15px;

        @media @max-small {
            padding: 10px;
        }
    }

}


.extralsxps {
    display: flex;
    width: 100%;
    .map-routesdsply {
        width: 50%;
        padding: 0 0px 10px 18px;
        img {
            max-width: 100%;
            width: 100%;
            height: auto;
            border: 1px solid rgb(184, 184, 184);
            border-radius: 8px;
        }
    }
    ul.ant-timeline {
        width: 50%;
        padding: 0 0 10px 0;
        .orders-list__item-place__action {
            white-space: normal;
            margin-top: 20px;
            button.ant-btn {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .extralsxps {
        display: flex;
        flex-wrap: wrap;
        .map-routesdsply {
            width: 100%;
            padding: 10px 0 0;
        }
        ul.ant-timeline {
            width: 100%;
        }
    }
}





.orders-list__item-content {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 0;
    padding: 5px 0;

    .ant-timeline-item-head {
        background: transparent;
    }

    .ant-row {
        width: 100%;
    }

    .ant-divider-horizontal {
        margin: 8px 0;
    }

    .ant-tag {
        font-size: 15px;
        padding: 3px 7px;
    }
}

.orders-list__item__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media @max-xxsmall {

        &--main {

            > .ant-card-body {
                width: 100%;
            }

            .ant-divider {
                display: none;
            }

            .ant-tag {
                margin: 8px 0  0 !important;
                text-align: center;
            }

            .orders-list__item__header__inner {
                display: block;
            }

            .orders-list__item__header__extra {
                display: block;
                font-family: "Montserrat", "Open Sans", Helvetica,Arial,sans-serif !important;
                font-weight: bold;
            }

            .orders-list__item-data {
                display: block;
            }

            .orders-list__item-tag-actions {
                display: block;
                margin: 0 !important;
            }

            .orders-list__space {
                display: block;
                margin: 0;
            }
        }
    }

    .ant-tag {
        .ant-layout-ltr & {
            margin-right: 0;
            font-size: 15px;
            padding: 5px 12px;
        }
        .ant-layout-rtl & {
            margin-left: 0;
        }
    }

    &__extra {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .ant-tag {
            margin: 5px 0;
        }
    }

    &__status {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media @min-medium {
            flex: 1;
            justify-content: flex-end;
        }

        .ant-tag {
            margin: 5px 0;
        }
    }

    &__main {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.orders-list__item__header__inner {
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    margin: -8px 0;
    padding: 8px 0;

    @media @max-xsmall {

        .ant-divider {
            display: none !important;
        }

        .orders-list__item-data {
            .ant-layout-ltr & {
                margin-right: 10px;
            }
            .ant-layout-rtl & {
                margin-left: 10px;
            }
        }

    }

    .ant-layout-ltr & {
        padding-left: 12px;
        margin-left: 12px;
        border-left: 1px solid #e8e8e8;
    }
    .ant-layout-rtl & {
        padding-right: 12px;
        margin-right: 12px;
        border-right: 1px solid #e8e8e8;
    }
}

.orders-list__item__header__inline {
    display: flex;
    align-items: center;
}

.orders-list__item__flight-number {
    display: inline-flex;
    align-items: center;

    .ant-layout-ltr & {
        margin-right: 10px;
    }
    .ant-layout-rtl & {
        margin-left: 10px;
    }

    svg {
        width: 1.4em;
        height: 1.4em;
        opacity: 0.5;
        position: relative;
        top: 1px;

        .ant-layout-ltr & {
            margin-right: 5px;
        }
        .ant-layout-rtl & {
            margin-left: 5px;
        }
    }
}

.orders-list__item__type {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 !important;
    color: rgba(#000,0.5);

    @media @max-xsmall {
        .ant-layout-ltr & {
            margin-right: 10px;
        }
        .ant-layout-rtl & {
            margin-left: 10px;
        }
    }

    .anticon {
        position: relative;
        top: 1px;

        .ant-layout-ltr & {
            margin-right: 5px;
        }
        .ant-layout-rtl & {
            margin-left: 5px;
        }
    }

    strong {
        color: #000;
    }

}

.orders-list__item__id {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}

.orders-list__item__routes {
    padding-top: 20px;


    @media @max-large {

        .ant-timeline-item-head-custom {
            top: 4px;
            // transform: translate(-50%,0);

            .ant-layout-rtl & {
                right: 50%;
            }
        }

        .ant-timeline-item-content {
            // padding-top: 20px;
            // top: 1px;

            .ant-layout-rtl & {
                margin-left: 0 !important;
            }
        }
    }
    .ant-timeline-item {
        padding-bottom: 10px;
    }
    .orders-list__item-place {
        position: relative;
        top: 1px;
        display: flex;
        align-items: flex-start;
        max-width: 900px;

        @media @max-large {
            // align-items: center;
        }

        .ant-tag {
            margin-bottom: 0;
        }
    }
    .ant-timeline-item-last {
        margin: 0;
        padding: 0;

        .ant-timeline-item-content {
            min-height: 10px;
        }
    }
}


.orders-list__item-data {
    // display: inline-flex;

    &--wrap {
        flex-wrap: wrap;
    }

    .anticon {
        opacity: 0.5;

        .ant-layout-ltr & {
            margin-right: 5px;
        }
        .ant-layout-rtl & {
            margin-left: 5px;
        }
    }
}

.orders-list__route-payment {
    display: inline-flex;
    align-items: center !important;
    font-weight: 600;
    font-size: 17px;

    svg {
        .ant-layout-ltr & {
            margin-right: 10px;
        }
        .ant-layout-rtl & {
            margin-left: 10px;
        }
    }
}

.orders-list__item-row {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -5px 0 0;

    .ant-tag {
        margin: 0;
    }

    .pull-left {
        flex: 1;
        display: flex;
        align-items: center;
        margin: 5px 0;
        white-space: nowrap;
        flex-wrap: wrap;

        .ant-layout-ltr & {
            margin-right: 10px;
        }
        .ant-layout-rtl & {
            margin-left: 10px;
        }

        @media @max-small {
            justify-content: center;
            .ant-layout-ltr & {
                margin-right: 0;
            }
            .ant-layout-rtl & {
                margin-left: 0;
            }
        }
    }

    .pull-right {
        display: flex;
        margin: 5px 0;
        white-space: nowrap;
        flex-wrap: wrap;
        @media @max-small {
            justify-content: center;
            width: 100%;

            .ant-divider {
                display: none;
            }

            .orders-list__item-data {
                width: 100%;
                justify-content: start;
                margin-bottom: 5px;
            }

            .ant-tag {
                margin: 5px;
            }
        }
    }
}

.orders-list__item-row--full {
    flex: 0 0 100%;
    max-width: 100%;

    @media @max-xmedium {
        order: 2;
    }
}

.orders-list__item-place {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    @media @max-large {
        align-items: center;
        // text-align: center;
    }

    @media @max-large {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .anticon-arrow-right {
        position: relative;
        top: 4px;
    }

    &__action {
        white-space: nowrap;

        .ant-layout-rtl & {
            margin-right: 20px;
        }

        @media @max-large {
            margin-top: 15px;
        }
    }
}

.orders-list__item-place-text {

    font-family: "Montserrat", "Open Sans", Helvetica,Arial,sans-serif !important;
    font-size: 15px !important;

    @media @max-large {
        white-space: normal;

        .orders-list__item-place-icon {
            display: inline-block;
        }
    }

    @media @max-small {
        // text-align: center;
        max-width: 80%;
    }

    

}

.order-steps-item {
    margin-left: 15px;
}

.orders-list__item-place-icon {


    @media @max-large {
        // transform: rotate(90deg) !important;
        margin: 10px 0 !important;
    }
}

.orders-list__item-actions {
    align-self: flex-start;
    margin-left: 20px;

    .ant-layout-ltr & {
        margin-left: 20px;
    }
    .ant-layout-rtl & {
        margin-right: 20px;
    }

    .ant-tag {
        width: 100%;
        display: block;
        margin: 0 0 5px;
       // text-align: center;
        height: auto;

        a, span {
            display: block !important;
            padding: 3px 3px !important;
        }
    }

    .ant-tag:last-child {
        margin-bottom: 0;
    }

    @media @max-xmedium {
        margin-top: 5px;
        order: 3;

        .ant-layout-ltr & {
            margin-left: 0;
        }
        .ant-layout-rtl & {
            margin-right: 0;
        }
    }
}

.visaCardLogo{
    margin: -1px 5px 0 5px;
    vertical-align: middle;
    display: inline-block;
}

.driverPhone {
    float: left;
    line-height: 4px;
}


span.order-route__data {
	display: inline-flex;
	padding: 0 5px;
	del {
		display: inline-flex;
		strong {
			margin: 0;
		}
	}
	strong {
		margin: 0 5px;
	}
}
.orders-list__item__header__inner {
	>p {
		margin: 0;
		padding: 0;
		padding-left: 3px;
	}
}
.orders-list__item__header__status {
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	//max-width: 145px;
	margin-left: auto;
	.orders-list__space {
		display: block;
		margin-right: 0;
		button.ant-btn {
			margin-right: 0 !important;
			padding: 2.4px 12px;
		}
	}
}
.ant-layout-ltr {
	.orders-list__space {
		&:last-child {
			margin-right: 0;
		}
	}
}

.ant-popconfirm-buttons{
    .ant-btn {
        font-size: 14px;
        padding: 3px 7px;
        height: auto;
        min-width: 46px;
    }
} 


@media @max-large {
	.ant-layout-ltr {
		.orders-list__item__header__inner {
			>p {
				width: 100%;
				padding: 0;
			}
			span.order-route__data {
				width: 100%;
				padding: 0;
			}
			position: relative;
			padding-right: 160px;
			min-height: 58px;
		}
	}
	.orders-list__item__header__status {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}
@media @max-medium{
	.ant-layout-ltr {
		.orders-list__item__header__inner {
			padding-right: 0px;
		}
	}
	.orders-list__item__header__status {
		position: absolute;
		top: 0;
		right: inherit;
		transform: none;
		margin-left: 0;
		margin-right: auto;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 5px;
	}
}