.ant-legacy-form-item-label {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 16px;
}

.ant-legacy-form-item-required {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 16px;
}


.date-picker .ant-picker-date-panel {
    max-width: 100%;
    width: 380px;
}

.date-picker .ant-picker-date-panel .ant-picker-content {
    width: 100%;
}

.date-picker .ant-picker-cell .ant-picker-cell-inner:after {
    content: "●";
    font-size: 10px;
    color: green;
    position: absolute;
    right: 0;
    bottom: -5px;
    display: flex;
}

.date-picker .ant-picker-cell .ant-picker-cell-inner {
    padding: 0 5px 6px;
}

.date-picker .ant-picker-cell-disabled .ant-picker-cell-inner {
    text-decoration: line-through;
}

.date-picker .ant-picker-cell-disabled .ant-picker-cell-inner:after {
    display: none;
}

.date-picker .ant-picker-cell-disabled::before {
    background: transparent !important;
}
.date-picker .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
    display: none;
}

.date-picker .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
    pointer-events: none;
}

.date-picker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    display: none;
}

.date-picker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    background: #fbfcff;
}

.date-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: #fff;
    background: #005093;
}

.ant-row.tour-tab {
    position: relative;
    display: flex;
    transition: transform 0.3s;
    margin: 0 !important;
    padding: 16px 20px 0;
    border-bottom: 1px solid #f0f0f0;
}

.ant-row.tour-tab .tabs-order-link {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0 12px 4px;
    font-size: 17px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #141516;
}


.ant-row.tour-tab .tabs-order-link {
    margin-top: 10px;
    margin-bottom: 12px;
}

.ant-row-rtl .tabs-order-link .anticon {
    margin-right: 0px;
    margin-left: 2px;
}

.ant-row.tour-tab .tabs-order-link:hover {
    color: #1a68a1;
}

.participants-section .ant-picker-suffix {
    fill: currentColor;
    color: currentColor;
}
.participants-section .ant-card-body > .ant-row {
    margin: 0 -4px !important;
    align-items: flex-start !important;
}

.participants-section .ant-row > .ant-col-12, .participants-section .ant-row > .ant-col-24 {
    padding: 0 4px 0px !important;
    margin: 0 0 8px 0 !important;
}

.phoneNumber label {
    display: block;
    text-align: left;
    font-size: 16px !important;
}

.participants-section button.dropdown-main, .participants-section .ant-form-item .ant-picker {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 4px;
    height: 40px;
    justify-content: space-between;
    line-height: 0;
    margin: 0;
    padding: 0 8px;
}
.participate-btn .ant-btn {
    border: 2px solid #0071eb;
    border-radius: 5rem;
    color: #fff;
    outline: 0;
    transition: background-color .2s ease-out;
    height: 40px;
    background: #0071eb;
}

.participate-btn .ant-btn:hover {
    background: #3662ba;
    border-color: #3662ba;
}

.participants-section .ant-card-body {
    padding: 20px 16px;
}


.participants-section .ant-card-body .partici-dropdown .ant-row {
    justify-content: space-between;
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid #ebeef1;
}
.participants-section .ant-card-body .partici-dropdown .ant-row:last-child { 
    border-bottom: none;
}

.participants-section .ant-card-body .partici-dropdown .ant-col {
    padding: 0 4px !important;
    margin: 0 !important;
    width: auto !important;
    flex: none !important;
    max-width: inherit !important;
}
.participants-section .ant-row > .ant-col-12 .ant-legacy-form-explain {
/*    position: absolute;*/
/*    bottom: -9px;*/
/*    z-index: 99999;*/
    font-size: 14px;
}

.participants-section .ant-row > .ant-col-12 .has-error {
    margin-bottom: 15px;
}
.partici-dropdown .ant-row h5.ant-typography {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-between;
    line-height: 1.375rem;
    margin: 0;
}

.partici-dropdown .ant-row span.ant-typography {
    color: #63687a;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.375rem;
    width: 100%;
    display: block;
}

.partici-dropdown .ant-row button.ant-btn.ant-btn-circle {
    border-color: #0071eb;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px;
    margin: 8px 16px !important;
}
.partici-dropdown .ant-row button.ant-btn.ant-btn-circle span.anticon {
    font-size: 12px;
    color: #0071eb;
}
.partici-dropdown .ant-row  .ant-input-number {
    margin: 0;
}
.main-participant-box .ant-card.partici-dropdown .ant-input-number {
    min-width: 60px;
}
.partici-dropdown .ant-row .ant-input-number input {
    text-align: center;
    padding: 0 !important;
    height: 34px;
}

.partici-dropdown .ant-row button.ant-btn.ant-btn-circle.input-count__minus span.anticon, 
.partici-dropdown .ant-row button.ant-btn.ant-btn-circle.input-count__plus span.anticon {
    color: #fff;
}

.main-participant-box .ant-card.partici-dropdown .ant-input-number input.ant-input-number-input {
    text-align: left;
    padding-left: 10px !important;
}

.ant-spin-container {
    overflow: visible;
}

.tour-tab .tabs-order-link {
    margin: 0 32px 0 0;
}
.ant-row.phoneNumber .ant-form-item-label {
    text-align: left;
}
.ant-row.phoneNumber .react-tel-input .form-control {
    width: 100%;
}
.main-participant-box .edit-down {
    bottom: auto !important;
}
.main-participant-box .edit-down .input-count {
    justify-content: flex-end;
}
.ant-input-group-compact .ant-input-number {
    height: 34px;
}

main.ant-layout-content .ant-row.spacing-input .ant-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

@media screen and (max-width: 573px) {

    .ant-row.about-row {
        padding: 10px 0 !important;
    }
    .ant-row.experince {
        display: block;
    }
    
    .ant-row.experince > .ant-col {
        max-width: 100%;
        flex: none;
    }
  .participants-section .ant-card-body > .ant-row .ant-col {
      width: 100%;
      max-width: 100%;
      flex: none;
  }

  .main-participant-box .ant-layout-ltr .input-count .ant-btn-group {
    margin-left: 0;
    }
    .main-participant-box .ant-card .ant-card-body {
        padding: 10px;
    }
  .ant-row.about-ticket .ant-space.ant-space-horizontal {
      gap: 5px !important;
  }
  .tour-tab .tabs-order-link {
    padding: 12px 0;
    font-size: 16px;
    color: #141516;
    
}
  h3.ant-typography, div.ant-typography-h3, div.ant-typography-h3 > textarea, .ant-typography h3 {
      font-size: 20px;
      line-height: 1.35;
  }
  h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
      font-size: 15px;
      line-height: 1.2;
  }
  .ant-form {
      font-size: 14px;
  }
  h4.ant-typography, div.ant-typography-h4, div.ant-typography-h4 > textarea, .ant-typography h4 {
      font-size: 18px;
      line-height: 1.2;
  }
  .image-group-main .ant-row .image-gird_0 .ant-image img.ant-image-img {
      height: 166px !important;
  }
  .image-group-main .ant-row .ant-col .ant-image img {
      height: 80px !important;
  }
  .image-group-main .ant-row.img_1 img.ant-image-img {
        height: auto !important;
    }

    .participants-section .ant-card-body .partici-dropdown .ant-row {
        flex-wrap: nowrap;
    }
    .participants-section .ant-card-body .partici-dropdown .ant-col:last-child {
        min-width: 100px;
    }
    .participants-section .ant-card-body .partici-dropdown .ant-col:first-child {
        max-width: 45% !important;
    }
    .partici-dropdown .ant-row h5.ant-typography {
        font-size: 12px;
        line-height: normal;
    }
    .partici-dropdown .ant-row span.ant-typography {
        font-size: 12px;
        line-height: normal;
    }
    .partici-dropdown .ant-row button.ant-btn.ant-btn-circle {
        width: 19px !important;
        height: 19px !important;
        min-width: 19px;
        margin: 8px 5px !important;
    }
    .ant-layout-ltr .input-count .ant-btn-group {
        margin-left: 0;
    }
    .participants-section .ant-card-body > .ant-row {
        margin: 0 0px !important;
    }
    .ant-card-body {
        padding: 15px;
    }
    .order-form-contact .ant-card-body {
        padding: 10px;
    }
    .ant-form label {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }
    .ant-btn-lg {
        font-size: 14px;
    }
    .ant-input {
        font-size: 14px;
    }

    .react-tel-input .form-control {
    max-width: 100%;
    }

    .edit-summary .order-form__price {
        padding: 0 12px;
        margin: 10px 0 20px;
    }
    .edit-summary .ant-list-item {
        padding: 7px 0;
    }
    .edit-summary .order-form__total {
        font-size: 12px;
    }
    .edit-tour-booking h4 {
        font-size: 18px;
    }

    .edit-tour-booking h2 {
        font-size: 18px;
    }
    .ant-layout-ltr .orders-list__item__header__inner .orders-list__item-data {
        display: inline-block;
        margin: 0 0 5px 0 !important;
    }
    
    .orders-list__item__header--main .orders-list__item-tag-actions {
        display: flex;
        flex-wrap: wrap;
    }
    
    .orders-list__item__header--main .orders-list__item-tag-actions span {
        margin-left: 2px !important;
    }
    .tour-main-bookinglist .orders-list__space span.ant-tag {
        margin: 0 0 0 5px !important;
        font-size: 12px;
    }
    .ant-layout-ltr .orders-list__item__header .ant-tag {
        margin-right: 0;
        font-size: 12px;
        padding: 5px 5px;
    }
    .tour-main-bookinglist .triptitle {
        font-size: 14px;
    }
    .ticketinfo > div {
        min-width: 48%;
    }
    .ant-modal.confirmation-mail {
        top: 20px;
    }
    main.ant-layout-content .ant-legacy-form-item-children .ant-row .ant-col {
        padding-left: 10px !important;
    }
    main.ant-layout-content .ant-legacy-form-item-children .ant-row .ant-col:first-child {
        padding-left: 0 !important;
    }
}

