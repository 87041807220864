.container {
	max-width: @max-width;
	padding: 0 18px;
	margin: auto;
	@media @max-large {
		padding: 0 13px;
	}
}

.container-header {
	max-width: @max-width;
	padding-right: 10px;
	padding-left: 10px;
	margin: auto;
}

html {
	width: 100%;
}
body {
	width: 100%;
}
.tabs-order-link {
	line-height: normal;
	white-space: normal;
	position: relative;
	z-index: 0;
	max-width: 400px;
}

.ant-tabs-rtl {
	.ant-tabs-nav {
		.ant-tabs-tab {
			.anticon {
				margin-left: 2px !important;
			}
		}
	}
}
 
@media screen and (max-width:480px) {
	.ant-layout-ltr .search-input .ant-input {
		max-width: 260px !important;
	}  
}
label.ant-legacy-form-item-no-colon span svg {
    margin-right: 8px;
}

.headtitlecst span {
    font-size: 17px !important;
	font-weight: 500 !important;
	font-family: 'Montserrat' !important;
	color: #033093;
}

.headtitlecstbutton {
    font-size: 15px !important;
	font-weight: 500 !important;
	color: #a40508;
}


.image-group-main {
	.ant-row {
		margin: 0 !important;
		display: grid;
		flex-flow: nowrap !important;
		grid-gap: 8px;
		grid-template-columns: 4fr 1fr 1fr;
		height: 100%;
		position: relative;
		width: 100%;
		.image-gird_0 {
			border-bottom-left-radius: 2px;
			border-top-left-radius: 2px;
			grid-column: 1;
			grid-row: 1 e("/") 3;
			.ant-image {
				img.ant-image-img {
					height: 299px !important;
				}
			}
		}
		.ant-col {
			cursor: pointer;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center;
			object-position: center;
			overflow: hidden;
			transition: box-shadow .2s;
			width: 100%;
			max-width: 100% !important;
			flex: none !important;
			padding: 0 !important;
			.ant-image {
				width: 100%;
				height: auto;
				img {
					width: 100% !important;
					object-fit: cover;
					height: 146px !important;
					display: block;
				}
			}
		}
		.image-gird_1 {
			border-top-right-radius: 2px;
			grid-column: 2 e("/") 4;
			grid-row: 1;
			img {
				height: 196px !important;
			}
		}
		.image-gird_2 {
			grid-column: 2;
			grid-row: 2;
		}
		.image-gird_3 {
			border-bottom-right-radius: 2px;
			grid-column: 3;
			grid-row: 2;
		}
	}
	.ant-row.img_4 {
		.image-gird_1 {
			border-top-right-radius: 2px;
			grid-column: 2 e("/") 4;
			grid-row: 1;
		}
	}
	.ant-row.img_3 {
		.image-gird_2 {
			border-top-right-radius: 2px;
			grid-column: 2 e("/") 4;
			grid-row: 2;
		}
	}
	.ant-row.img_2 {
		.image-gird_1 {
			.ant-image {
				img {
					height: 299px !important;
				}
			}
		}
	}
	.ant-row.img_1 {
		display: block !important;
		img.ant-image-img {
			height: auto !important;
		}
	}
}


@media screen and (max-width:767px) {
	

	.ant-layout-ltr {
		.search-input {
			.ant-input {
				padding-right: 25px !important;
			}
		}
	}
	button, [role='button'], input:not([type='range']), label, select, summary, textarea {
		touch-action: auto !important;
	}
}

.ant-tabs-nav {

	margin: 0 0 6px 0 !important;

	.ant-tabs-nav-operations {
		display: none !important;
	}
}

button {
	overflow: hidden;
}

input {
	overflow: auto;
} 

main.ant-layout-content {
    min-height: 80vh;
}
.order-form .ant-select-clear {
	.ant-layout-ltr & {
        right: 60px !important;
    }
    .ant-layout-rtl & {
		left: 60px !important;
		right: auto !important;
    }
}


.ant-form-explain {
	color:red;
}

h1 {
	font-size: 21px;
	font-weight: 700;
}

.ant-layout-content {
	margin: 0px 16px 60px;

	.order-form {

		.ant-btn-rtl .ant-select-clear {
			margin-right: 0px;
			margin-left: 50px !important;
		}
	}

	.ant-layout-content {
		margin: 0;
	}

	@media @max-large {
		margin-left: 0;
		margin-right: 0;
	}

    @media @max-small {
        margin: 1px -15px 0;
    }
}

.ant-layout-has-sider {
	@media @max-medium {
		flex-wrap: wrap;
	}
}

.content {
	background-color: @color-white;

	&__inner {
		padding: 15px 12px 0px 12px;
	}

}

.content__sider {
	width: 200px;

	.ant-menu {
		height: 100%;
		padding: 24px 0;
	}

	@media @max-medium {
        margin-top: 20px;
		flex: 0 0 100%!important;
		width: 100%!important;
		max-width: 100%!important;
		order: 2;

		.ant-menu {
			height: auto;
            padding: 12px 0;
		}
	}
}

.has-error .ant-picker,
.has-error .ant-input-affix-wrapper {
	border-color: red;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child) {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: unset !important;
}

.ant-layout-rtl input.ant-input-rtl[type="email"] {
    direction: ltr;
    text-align: right;
}
.ant-layout-rtl input[type="tel"] {
    direction: ltr;
    text-align: right;
}

.ant-popover-inner-content {
    max-width: 400px !important;
	background-color: #fff9f9fa;
	font-size: 15px !important;
	padding: 4px 5px !important;
	border-radius: 4px;
}

.main-participant-box {
	position: relative;
	button.ant-btn {
		text-align: left;
	}
	button.dropdown-main {
		display: flex;
		align-items: center;
		span.anticon {
			margin-left: auto;
		}
		span.anticon.anticon-usergroup-add{
			margin-left: 0px;
		}
	}
	.ant-card {
		position: absolute;
		bottom: 103%;
		z-index: 9;
		border-radius: 4px;
		padding: 0px;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
		&-body {
			padding: 20px;
			.ant-row {
				align-items: center;
			}
		}
		.ant-input-number {
			width: 40px;
			margin-left: 5px;
			border-color: #dfdfdf;
			input.ant-input-number-input {
				appearance: none !important;
			}
		}
		h5 {
			font-size: 16px;
		}
		button.ant-btn.ant-btn-circle {
			border-radius: 50% !important;
			border-color: rgb(0, 80, 147);
			border-width: 2px;
		}
	}
	&.checkcls{
		.ant-card {
			top: 102%;
			bottom: auto;
		}
	}
	.date-picker{
		.ant-row.ant-legacy-form-item {
			width: 100%;
			display: block;
		}
	}
}
.ant-row.ant-form-item.date-picker {
	.ant-col.ant-legacy-form-item-control-wrapper {
		width: 100%;
	}
}

.tourgrid {
	.ant-card-cover {
		img {
			border-radius: 2px 2px 0 0;
			height: 180px;
			width: 100%;
			object-fit: cover;
		}
	}
	.ant-card-bordered {
		border: 1px solid #f0f0f0;
		height: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.ant-tabs-tab {
		margin: 0 32px 0 0 !important;
		&+.ant-tabs-tab {
			margin: 0 32px 0 0 !important;
		}
	}
	.order-type {
		.ant-tabs-nav-wrap {
			.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
				display: none !important;
			}
			.ant-tabs-tab {
				border-bottom: 2px solid transparent !important;; 
			}
			.ant-tabs-tab.ant-tabs-tab-active {
				border-bottom-color: #005093 !important;;
			}
	
		}
	}


	
}
@media screen and (max-width: 573px) {

	.ant-tabs-tab {
		margin: 0 20px 0 0 !important;
		&+.ant-tabs-tab {
			margin: 0 20px 0 0 !important;
		}
	}
	.tabs-order-link {
		.anticon {
			svg {
				width: 100%;
				height: auto;
			}
		}
	}

	.ant-layout-ltr .order-form .ant-select-clear{
		right:5px !important;
	}
	.header__wrapper {
		margin: 0px !important;
	}
	.ant-layout-content {
		margin: 1px 0px 0 !important;
	}
	main.ant-layout-content .ant-row {
		margin: 0 !important;
	}
	main.ant-layout-content .ant-row .ant-col {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 5px;
		padding-top: 5px;
	}
    .ant-steps-item.ant-steps-item-finish {
        flex: 1;
    }

    .ant-steps-item.ant-steps-item-finish + .ant-steps-item.ant-steps-item-process.ant-steps-item-active:after, .ant-steps-item.ant-steps-item-finish:after {
        background: #005093;
        content: "";
        width: 100%;
        right: 0;
        height: 2px;
        position: absolute;
        top: 2px;
    }
	.ant-steps-item.ant-steps-item-finish .ant-steps-item:first-child .ant-steps-icon-dot {
        left: 0;
        top: -1px;
    }
    .ant-steps-item.ant-steps-item-finish + .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
        margin: 0;
        right: 0;
        left: auto;
    }
    .ant-steps-item.ant-steps-item-finish + .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon-dot {
        left: auto;
        right: 0px;
    }
	.ant-steps-vertical > .ant-steps-item {
		flex: 1 !important;
	}
	.ant-steps-vertical > .ant-steps-item:last-child {
		flex: none !important;
	}
	.ant-steps-vertical > .ant-steps-item:last-child .ant-steps-item-container .ant-steps-item-icon {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 0 !important;
	}
	
	.ant-steps-vertical > .ant-steps-item:last-child .ant-steps-item-container .ant-steps-item-icon span.ant-steps-icon {
		width: 8px;
		height: 8px;
		background: #ccc;
		border-radius: 8px;
	}
	.ant-steps-vertical > .ant-steps-item:last-child .ant-steps-item-container .ant-steps-item-icon span.ant-steps-icon span.ant-steps-icon-dot {
		display: none;
	}
	.ant-steps-vertical > .ant-steps-item.ant-steps-item-process.ant-steps-item-active span.ant-steps-icon {
		background: #005093 !important;
	}

	.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
		min-height: 35px !important;
	}
}

.ticketinfo {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	width: 100%;
	>div {
	    padding:5px 10px 5px 0;
		margin: 0 !important;
		gap: 2px !important;
		min-width: 25%;
		h5.ant-typography {
			font-size: 14px;
			margin: 0;
		}
		.ant-typography.ant-typography-secondary {
			font-size: 14px;
		}
	}
}

.userInfo {
	flex-wrap: wrap;
	display: flex;
	margin: 0;
	width: 100%;
	align-items: center;
	>span {
		padding: 5px 10px 5px 0;
	}
}
.tour-main-bookinglist {
	.triptitle {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: 17px;
		font-weight: bold;
		.statusName {
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			max-width: 145px;
			margin-left: auto;
			.orders-list__item__header__status {
				position: static;
				transform: none;
			}
		}
		.unconfrimOrder {
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
		}
	}
	.ant-card-head-title {
		white-space: normal;
	}
	.orders-list__item__header__status {
		.ant-tag {
			margin: 5px 0;
			margin-right: 0;
			font-size: 15px;
			padding: 5px 12px;
		}
	}

	.main-counterdiv {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: rgba(0, 0, 0, 0.85);
		font-size: 15px;
		line-height: 1.5715;
		list-style: none;
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
		.NumberOfPeople {
			>span {
				border-right: 1px solid rgba(0, 0, 0, 0.06);
				margin: 0 10px 0 0;
				padding-right: 10px;
				&:last-child {
					border-right: none;
				}
				&:first-child {
					border-right: none;
					margin-right: 0;
				}
			}
		}
	}
	.userInfo {

		color: rgba(0, 0, 0, 0.85);
		font-size: 15px;
		line-height: 1.5715;
		>span {
			border-right: 1px solid rgba(0, 0, 0, 0.06);
			margin: 0 10px 0 0;
			padding-right: 10px;
			&:last-child {
				border-right: none;
			}
			.anticon {
				margin-right: 6px;
			}
		}
	}
	.orders-list__route-payment {
		white-space: nowrap;
		.anticon {
			svg {
				margin: 0;
			}
		}
	}
	
	.orders-list__space {
		span.ant-tag {
			margin: 0 0 0 10px !important;
		}
	}
} 

.have-accountingDocuments {
    display: flex;
    align-items: center;
	gap: 10px;
}

@media(max-width:767px){
	.react-tel-input{
		.form-control{
			width: 100% !important;
		}
	} 
	:where(.css-dev-only-do-not-override-bixahu).ant-col-sm-12 {
		max-width: 100% !important;
	}
}

