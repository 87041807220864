// --------------------------
// Breakpoints
// --------------------------
@min-xxsmall: ~"screen and (min-width: 380px)";
@min-xsmall: ~"screen and (min-width: 480px)";
@min-small: ~"screen and (min-width: 575px)";
@min-medium: ~"screen and (min-width: 768px)";
@min-xmedium: ~"screen and (min-width: 991px)";
@min-large: ~"screen and (min-width: 1240px)";
@min-menu: ~"screen and (min-width: 1099px)";

@max-xxsmall: ~"screen and (max-width: 379px)";
@max-xsmall: ~"screen and (max-width: 479px)";
@max-small: ~"screen and (max-width: 574px)";
@max-medium: ~"screen and (max-width: 767px)";
@max-xmedium: ~"screen and (max-width: 990px)";
@max-large: ~"screen and (max-width: 1239px)";
@max-menu: ~"screen and (max-width: 1099px)";


// --------------------------
// Color palette map
// --------------------------

@color-white: #ffffff;
@color-gray: #757575;
@color-black: #000000;
@color-primary: #c0392b;
@color-secondary: #677fec;
@color-xlight: rgb(90, 120, 239);
@color-light: #025da8;
@color-dark: #273568;


// --------------------------
// Link colors
// --------------------------
@link-color: @color-black;
@link-color--hover: darken(@link-color, 10);
@link-color--visited: @link-color--hover;
@link-color--focus: darken(@link-color--hover, 10);


// --------------------------
// Type scale map
// --------------------------
@base-font-size: 16px;

html,
body {
    font-size: @base-font-size;
    height: 100%;
    background-color: #ffffff;
    color: @color-black;

    @media @max-small {
        font-size: 16px;
    }
}

// --------------------------
// Other
// --------------------------
@max-width: 1260px;
@transition: "all 0.30s cubic-bezier(0.23, 1, 0.32, 1)";
