.orders-table {
    tbody > tr > td, tr > th {
        padding: 10px;
    }

    tr > th:last-of-type {
        width: 96px;
    }
}

.orders-table__overflow {
    overflow-x: auto;

    .orders-table {
        min-width: 900px;
    }
}

.orders-table__action-button {
    width: 100%;
    text-align: center;

    & + & {
        margin-top: 5px;
    }
}

.orders-table__add-new {
    .ant-layout-ltr & {
        text-align: right;
    }
    .ant-layout-rtl & {
        text-align: left;
    }

    @media @max-medium {
        margin-top: 10px;

        .ant-layout-ltr & {
            text-align: left;
        }
        .ant-layout-rtl & {
            text-align: right;
        }
    }
}

.orders-table__wrapper {
    margin-top: 15px;

    @media @max-small {
        margin-top: 30px;
    }
}

.orders-table__price {
    min-width: 105px;
}

.orders-table__export {
    display: block;
    .ant-layout-ltr & {
        float: right;
    }
    .ant-layout-rtl & {
        float: left;
    }
}

.orders-table__date-picker {
    width: 100%;



    @media (max-width: 768px) {
        display: none; /* Hide RangePicker on mobile */
    }
}