.StripeCheckout input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}



.StripeCheckout input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.strppaymentcheckbox .ant-radio-group,
.strppaymentcheckbox li.ant-list-item label.ant-radio-wrapper{
 width:100%;
}
.strppaymentcheckbox label.ant-radio-wrapper span.ant-radio span.ant-radio-inner {
  width: 15px !important;
  height: 15px !important;
}
.payment_radiocheck {
  position: relative;
}
.strppaymentcheckbox .payment_radiocheck span.ant-radio {
  width: auto;
}
.strppaymentcheckbox label.ant-radio-wrapper span {
  width: 100%;
}


.strppaymentcheckbox label.ant-radio-wrapper span.ant-radio input.ant-radio-input {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin:0px;
}
.strppaymentcheckbox label.ant-radio-wrapper span.ant-radio span.ant-radio-inner {
  height: 100%;
}
.strppaymentcheckbox .card-badge {
  display: flex;
  padding: 0px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.strppaymentcheckbox .card-badge > span {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.strppaymentcheckbox .card-badge .card-detail {
  width: 100%;
  text-align: right;
  padding: 0 0 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.strppaymentcheckbox .card-badge .card-detail .removecrd {
  width: 30px;
  height: 30px;
  border: 0px;
  margin-left: 30px;
  background: #ce5a2b;
  cursor: pointer;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.strppaymentcheckbox .card-badge .card-number {
  font-size: 18px;
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
  text-align: right;
}

.strppaymentcheckbox .card-detail .exp-mnth {
  min-height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 0px;
}
.strppaymentcheckbox .cardhax:checked + .card-badge .cvv {
  display: inline-block;
}
.strppaymentcheckbox .card-badge input {
  width: 50px;
  text-align: left;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  background: #fff;
  line-height: normal;
}
.strppaymentcheckbox .card-badge input {
  width: 100px;
  text-align: left;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  background: #fff;
  line-height: normal;
  margin: 0px;
}
.strppaymentcheckbox .card-detail .exp-mnth span.cvv{
  width: auto;
  margin-left:15px;
}
.or-text {
  text-align: center;
  font-size: 25px;
}
.ant-row.card-details {
  margin: 0px !important;
}
.card-details-box.ant-card-bordered {
  padding: 25px !important;
  background: #f9f9f9;
}
.card-details-box label {
  font-weight: 600;
}
.ant-row.card-button {
  margin-top: 25px;
  text-align: center;
  width: 100%;
}
.ant-row.card-button button.ant-btn {
  min-width: 200px;
  padding: 10px 20px !important;
  height: auto;
}
.ant-row.card-button button.ant-btn span {
  margin-right: 10px;
}
.ant-row.card-button button.ant-btn span:last-child {
  margin-right: 0px;
}
p.error {
  color: red;
}
.card-details-box input[type=number]::-webkit-inner-spin-button,
.card-details-box input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.strppaymentcheckbox .card-detail .exp-mnth {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.payment_radiocheck .ant-image img.ant-image-img {
  max-width: 80px;
  width: 100%;
}

@media(max-width:767px){
  .stripe-main-page .ant-spin-container>.ant-row>.ant-col {
      max-width: 95% !important;
      width: 100% !important;
      flex: 100% !important;
  }
  .stripe-main-page .ant-spin-container>.ant-row>.ant-col .ant-card-body {
      padding: 15px;
  }
  .StripeCheckout>form.ant-form>.card-details .ant-card {
      width: 100% !important;
  }
  .or-text {
    font-size: 20px;
}
.strppaymentcheckbox .card-badge .card-detail {
  width: 80%;
  flex-direction: column;
  align-items:flex-end;
}
.strppaymentcheckbox .card-badge .card-detail .removecrd {
  margin-left: 0px;
  margin-top: 15px;
}
}
  .StripeCheckout>form.ant-form>.card-details>.card-details-box {
      display: none;
  }

  