.extras-item {

    width: 100%;
    height: 100%;
    cursor: default;
    display: flex;
    flex-direction: column;
    position: relative;

    &__counter {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }

    &__counter-badge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 50;
    }

    &__price {
        color: #000;
        font-size: 14px;

        & + & {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #f0f0f0;
        }

        &--total {
            font-weight: bold;
        }
    }

    &__price-label {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.45);
    }

    &__remove {
        position: absolute;
        top: -17px;
        right: -2px;
        color:  #750909;
        z-index: 400;
        font-size: 25px;
        cursor: pointer;
    }

    &__wrapper {
        height: 100%;
        padding-bottom: 20px;
    }

    &__img {
        height: 120px;
        border-bottom: 1px solid #f7f7f7;
        text-align: center;
        padding: 4px 2px 2px 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
            font-size: 60px;
            color: #ccc;
        }

        img {
            max-height: 100%;
        }
    }


    .ant-card-meta {
        flex: 1;
    }


    .ant-card-meta-title {
        white-space: normal;
        text-overflow: initial;
        overflow: visible;
        font-weight: 600;
        font-size: 16px;
        color: #025ba7;
        font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif !important;
    }

    .ant-card-body {
        padding: 15px;
        display: flex;
        flex-direction: column;
        flex: 1;

        .ant-badge {
            display: inline-block;
            position: relative;
            z-index: 200;

            .ant-badge-count {
                z-index: 300;
                background: #f04e00;
            }
        }
    }
}

.extras_list_profile {
    font-size: 13px !important;
    height: 50px !important;

    .anticon {
        font-size: 40px;
        color: #ccc;
    }

    img {
        max-height: 100%;
        height: 90px;
    }
}
