
.ant-layout-rtl {
    direction: rtl
}

.ant-legacy-form .ant-legacy-form-item-label {
    text-align: left; /* Align text to the left */
}

.rtl {
    display: flex;
}

* {
    font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

h1, h3, h4 {
    font-family: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif !important;
    color: #002943;
}

h2, h5 {
    font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
    color: #041d5e;
    font-size: 20px;
    font-weight: 600;
}

.ant-notification-topRight {
    .ant-layout-ltr & {
        right: 50% !important;
        margin-right: -192px !important;
    }
    .ant-layout-rtl & {
        left: 50% !important;
        margin-left: -192px !important;
    }
}

.ant-spin-container {
    overflow: hidden;
}

.ant-fullcalendar-header {
    display: none;
}

ul.times {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}


.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    overflow: hidden;
    height: 100%;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-date, .ant-fullcalendar-fullscreen .ant-fullcalendar-month {
    height: 130px;
}

.order-detail-routes {
    margin-bottom: 30px;
}

.order-detail-content .ant-steps-item-title:after {
    display: none;
    font-size: 13px;
}

.order-detail-content .ant-steps-item-finish .ant-steps-icon,
.order-detail-content .ant-steps-item-finish .ant-steps-item-title,
.order-detail-content .ant-steps-item-finish .ant-steps-item-description {
    color: #87d068;
    font-size: 13px;
}

.order-detail-content .ant-steps-item-title {
    font-weight: bold;
    font-size: 13px;
}

.order-content .ant-steps,
.order-content .ant-steps-item,
.order-content .ant-steps-item-icon,
.order-content .ant-steps-icon {
    display: flex;
    align-items: center;
}

.order-content .ant-steps-icon {
    font-size: 18px !important;
    width: 20px !important;
}

.order-content {
    background: #f7f7f7;
    border-top: 1px solid #e8e8e8;
    margin: 20px -32px -24px;
    padding: 10px 32px;
}

.order-content .ant-steps-item-title:after {
    display: none;
}

.order-content .ant-steps-item-finish .ant-steps-icon,
.order-content .ant-steps-item-finish .ant-steps-item-title,
.order-content .ant-steps-item-finish .ant-steps-item-description {
    color: #87d068
}


.data-label {
    flex: 40%;
    max-width: 40%;
}

.data-value {
    font-weight: 700;
    flex: 1;
}

.data-value-info {
    font-weight: normal;
    color: #999;
}

.data-value-action {
    font-weight: normal;

    .ant-layout-ltr & {
        float: right;
    }
    .ant-layout-rtl & {
        float: left;
    }
}


.popup-edit .ant-modal-body {
    padding: 4px;
}

.popup-edit .ant-modal-header {
    border-radius: 5px 5px 0 0;
  }

.popup-edit .ant-modal-content {
    border-radius: 5px;
  }

  .popup-edit  .ant-modal-content {
    background-color: whitesmoke;
  }


.popup-edit .ant-tabs-tabpane {
    padding: 0 24px;
    margin: 24px 0;
}

.popup-edit .ant-tabs-nav {
    .ant-layout-ltr & {
        margin-left: 24px;
    }
    .ant-layout-rtl & {
        margin-right: 24px;
    }
}

.popup-edit .extra-tabs .ant-tabs-nav {
    .ant-layout-ltr & {
        margin-left: 0;
    }
    .ant-layout-rtl & {
        margin-right: 0;
    }
}

.timeline {
    position: relative;
    margin-top: 25px;
    margin-bottom: 40px;
}

.timeline-items {
    background: #ccc;
    height: 10px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.timeline-item {
    position: absolute;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 0 1px #fff;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.timeline-item.is-occupied {
    background: rgba(0,0,0,0.5);
}

.timeline-item.is-occupied:hover {
    background: rgba(0,0,0,0.7);
}

.timeline-item.is-required {
    background: #005093;
    box-shadow: none;
    top: -4px;
    bottom: -4px;
    border-radius: 3px;
    opacity: 0.8;
    z-index: 100;
}

.timeline-item.is-required:hover {
    opacity: 1;
}

.timeline-item.is-actual {
    background: red;
    color: red;
    box-shadow: none;
    top: -4px;
    width: 1px;
    bottom: -4px;
    opacity: 1;
    z-index: 110;
}

.timeline-item.is-actual span {
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    font-size: 12px;

    .ant-layout-ltr & {
        left: 0
    }
    .ant-layout-rtl & {
        right: 0
    }
}

.timeline-labels {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    justify-content: space-between;
    padding-top: 10px;
    z-index: 5;
}

.timeline-labels-item {
    position: relative;
    font-size: 12px;
    flex: 1;
}

.timeline-labels-item:before {
    content: '';
    position: absolute;
    top: -5px;
    height: 5px;
    width: 1px;
    background: #ccc;

    .ant-layout-ltr & {
        left: 0;
    }
    .ant-layout-rtl & {
        right: 0;
    }
}

.timeline-labels-item:last-child {
    position: absolute;
    bottom: 0;

    .ant-layout-ltr & {
        left: 100%;
    }
    .ant-layout-rtl & {
        right: 100%;
    }
}

.timeline-labels-item span {
    display: inline-block;
    transform: translateX(-50%);
}

.box {
    margin: -24px -24px 24px;
    padding: 24px;
    background: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;
}

.data-list dl {
    display: flex;
    margin: 0;
}

.data-list dt {
    flex: 30%;
    max-width: 30%;
}
.data-list dd {
    flex: 1;
    font-weight: bold;
}

.data-list .inline div {
    display: inline-block;
}

.data-list .inline {
    font-weight: normal;
}

.order-steps .ant-steps-item:nth-child(2) {
    text-align: center;
}
.order-steps .ant-steps-item-title:after {
    display: none;
}

.orders-filter {
    margin: -5px;
}
.orders-filter .ant-btn {
    margin: 5px;
}

.orders-filter .ant-badge {
    .ant-layout-ltr & {
        margin-left: 5px;
    }
    .ant-layout-rtl & {
        margin-right: 5px;
    }
}

.orders-filter .ant-badge-count {
    box-shadow: none !important;
}

.phoneNumber {
    padding-top: 6px !important;
    font-size: 16px !important;
}

/* Ensure the entire PhoneInput container aligns elements properly */
.phoneNumber .react-tel-input {
    display: flex;
    align-items: center; /* Vertical alignment */
    width: 100%;
}

/* Input should take full width but not overlap the country selector */
.phoneNumber .react-tel-input input {
    font-size: 16px !important;
    flex: 1;
    margin-left: 10px;
}

/* Country selector adjustments */
.phoneNumber .react-tel-input .flag-dropdown {
    display: flex;
    align-items: center;
    height: 100%; /* Ensure it matches the input height */
    padding: 0 5px; /* Adjust as needed for spacing */
    border-right: 1px solid #d9d9d9; /* Add border to match input design */
    background-color: #fff; /* Ensure clear background */
    cursor: pointer; /* Show as clickable */
}

.phoneNumber input::-webkit-outer-spin-button,
.phoneNumber input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 480px) {
    /* Mobile screens only */
    .header__language-hide-mobile {
        visibility: hidden;
        display: none;
    }
}


.ant-spin-nested-loading {
    min-height: 100px;
}

.order-form__summary,
.order-form__card {
    .ant-spin-nested-loading {
        min-height: 0;
    }
}


.order-form__card-estimate .ant-card-body {

    margin-top: 1px !important;
    margin-bottom: 4px !important;
    padding-top: 1px !important;
    padding-bottom: 4px !important;

    @media @max-small {
        display: none;
    }
    
    .ant-spin-nested-loading {
        min-height: 0;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}


/* Adjust AM/PM alignment to avoid overlap with the time selector */
.am-pm {
    position: relative;
}

.am-pm span.am-pm-value {
    position: absolute;
    right: 35px; /* Keep AM/PM inside for LTR */
    top: 50%;
    transform: translateY(-50%);
    color: #a7a7a9;
    font-size: 12px;

    .ant-layout-rtl & {
        display: none;
    }
}

.mr-10 {
    .ant-layout-ltr & {
        margin-right: 10px;
        font-size: 16px !important;
    }
    .ant-layout-rtl & {
        margin-left: 10px;
        font-size: 16px !important;
    }
}


.mr-12-1 {
    .ant-layout-ltr & {
        margin-left: 11px !important;
        margin-right: 8px !important;
    }
    .ant-layout-rtl & {
        margin-right: 11px !important;
        margin-left: 8px !important;
    }
}

.mr-5 {
    .ant-layout-ltr & {
        margin-right: 5px;
    }
    .ant-layout-rtl & {
        margin-left: 5px;
    }
}

.ml-10 {
    .ant-layout-ltr & {
        margin-left: 10px;
    }
    .ant-layout-rtl & {
        margin-right: 10px;
    }

    .ant-select-dropdown & {
        margin-left: 10px;
    }
    .ant-select-dropdown-rtl & {
        margin-right: 10px;
    }
}


.ml-eamil-veri {
    font-size: 15px;
    .ant-layout-ltr & {
        margin: 5px;
    }
    .ant-layout-rtl & {
        margin: 5px;
    }

    .ant-select-dropdown & {
        margin: 5px;
    }
    .ant-select-dropdown-rtl & {
        margin: 5px;
    }

    @media @max-small {
        font-size: 13px !important;
    }
}


.ml-5 {
    .ant-layout-ltr & {
        margin-left: 5px;
    }
    .ant-layout-rtl & {
        margin-right: 5px;
    }
}

.payall-modal-footer {
    margin-top: 15px;
    text-align: right;

    .ant-modal-rtl & {
        text-align: left;
    }
}
.autocomplete-item {
    display: flex;
    align-items: center;

    @media screen and (max-width: 639px) {
        margin: 0;
        align-items: flex-start;
        padding-top: 5px;

        .ant-layout-ltr & {
            margin-right: -10px;
        }
        .ant-layout-rtl & {
            margin-left: -10px;
        }
    }

    &__icon {
        position: relative;
        display: flex;
        align-items: center;

        .ant-layout-ltr & {
            margin-right: 10px;
        }
        .ant-layout-rtl & {
            margin-left: 10px;
        }
    }
    &__text {
        position: relative;
        bottom: 3px;
        white-space: normal;
        flex: 1;

        @media screen and (min-width: 640px) {
            padding-top: 4px;
        }

        @media screen and (max-width: 639px) {
            font-size: 13px;
        }

        span {
            font-size: 12px;
            color: #aaa;
            white-space: normal;

            @media screen and (min-width: 640px) {
                .ant-layout-ltr & {
                    margin-left: 10px;
                }
                .ant-layout-rtl & {
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: 639px) {
                display: block;
            }
        }
    }
}

.search-btn {
    .ant-layout-ltr & {
        margin-right: -12px !important;;
    }
    .ant-layout-rtl & {
        margin-left: -12px !important;;
    }
}

.search-input .ant-input {
    .ant-layout-ltr & {
        padding-right: 30px !important;
    }
    .ant-layout-rtl & {
        padding-left: 30px !important;
    }
}

.icons-inline-wrap {
    flex-wrap: wrap;
    align-items: center;
    margin: -5px 0;
    @media @max-small {
        justify-content: center;
        width: 100%;
    }
}

.icon-inline {
    display: inline-flex;
    align-items: center;
    margin: 5px 0;
    font-size: 16px;
}

.icon-inline span {
    .ant-layout-ltr & {
        margin-left: 8px;
    }
    .ant-layout-rtl & {
        margin-right: 8px;
    }
}

.icon-inline svg {
    width: 20px;
    height: 20px;
    font-size: 20px;
}

@media screen and (max-width: 480px) {
    .icon-inline--full {
        display: flex;
        width: 80%;
        /*justify-content: center;*/
    }


}

.ant-form-item-required:before,
.ant-legacy-form-item-required:before {
    display: none !important;
}

.settings-wraper  .ant-form-item-label>label:after,
.change-password-wraper .ant-form-item-label>label:after{
    content: " ";
}

.ant-message { 

    .ant-message-custom-content {
        padding: 18px;
    }

    .ant-message-notice {
        i {
            top: -1px !important;
        }
    }
}


.text-align--right {
    

    .ant-layout-ltr & {
        text-align: right;
    }
    .ant-layout-rtl & {
        text-align: left;
    }
    .ant-modal & {
        text-align: right;
    }
    .ant-modal-rtl & {
        text-align: left;
    }


}

.text-align--left {
    .ant-layout-ltr & {
        text-align: left;
    }
    .ant-layout-rtl & {
        text-align: right;
    }


}



.ant-picker-panel-rtl {
    .ant-picker-year-btn {
        margin-right: 10px;
        margin-left: 0;
    }
}

.ant-legacy-form-item-required::before {
    .ant-layout-ltr & {
        margin-right: 4px;
    }
    .ant-layout-rtl & {
        margin-left: 4px;
    }
}

.ant-alert-with-description.ant-alert-rtl {
    padding-right: 64px !important;
}

.ant-tabs-tabpane {
    outline: 0 !important;
}

.confirmation-mail {
    .ant-legacy-form-item-control-wrapper {
        width: 100%;
    }
}

.menu-overflow {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 17px;
    padding: 10px 10px 0px 10px;

    .ant-menu-item {
        margin: 0 !important;
        font-size: 17px;

        @media (max-width: 767px) {
            font-size: 16px;
        }

        a {
            margin: 0 1px;
            display: block;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .ant-menu-overflowed-submenu {
        display: none;
    }
}

.menu-overflow {
    .ant-menu-item {
        top: 0;
    }
}
.menu-overflow .menuitem-overflowed {
    display: block !important;
}

.order-type .ant-tabs-nav-wrap {
    margin: 0 !important;
    font-size: 17px !important;

    .ant-tabs-nav-list {
        padding: 0 20px;
        font-size: 17px !important;
    }
}

.profile-nav {
    position: relative;
    margin-top: 20px;

    @media (max-width: 767px) {
        margin-top: 10px;
    }

    &__partner {
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);

        .ant-layout-ltr & {
            right: 15px;
        }
        .ant-layout-rtl & {
            left: 15px;
        }

        @media @max-medium {
            top: auto;
            bottom: -35px;
            transform: translate(0,0);
        }
    }
}

@media (max-width: 767px) {
    .profile-nav__partner {
        display: none; // Hide the partner section on mobile
    }
}

.partners {

    &__add {
        margin-bottom: 30px;
        .ant-legacy-form-item-control-wrapper {
            width: 100%;
        }
    }

    &__list {
        margin: 0 -24px -24px;

        table thead th {
            font-weight: bold;
        }

        @media @max-medium {
            margin: 0 -10px -10px;
        }

        @media @max-xsmall {
            table {
                border: 0;
            }

            table thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            table tr {
                border-top: 1px solid #ddd;
                display: block;
                padding: 15px 75px 15px 15px;
                position: relative;
            }

            table td {
                display: block;
                text-align: left;
                border: 0;
                padding: 0;
            }

            table .partners__name {
                font-weight: bold;
            }

            table .partners__actions {
                position: absolute;
                top: 50%;
                transform: translate(0,-90%);
                right: 15px;
            }


            table td:last-child {
                border-bottom: 0;
            }
        }
    }
}

.change-password-wraper {
    .ant-legacy-form-item-control-wrapper {
        width: 100%;
    }
}

.help-button {
    color: #005093;
    margin: 0 8px;
    display: inline-block;
    cursor: help;


    .anticon {
        font-size: 14px;
    }
}

.ant-alert {
    border-radius: 7px !important;
    margin-bottom: 30px !important;
    margin-top: 20px !important;
    font-size: 15px !important; 
}


@media @max-menu {
    .ant-alert {
        margin: 15px !important;
    }
}

.ant-alert-with-description .ant-alert-description {
    margin-top: -5px;
}

.modal-invalid-address .ant-legacy-form-item-control-wrapper {
    width: 100%;
}

.modal-invalid-address .ant-modal-title {
    font-weight: 600;
}


.ant-legacy-form-item + .ant-legacy-form-item {
    margin-top: 15px;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label {
    margin-bottom: 5px;
}

.ant-legacy-form-item-label {
    color: rgba(0, 0, 0, 0.85);
}

.ant-legacy-form-explain {
    color: #ff4d4f;
    display: inline-block;
    width: 100%;
}

.has-error .ant-input {
    border-color: red;
}

.ant-form-item-has-error .ant-checkbox-inner {
    border-color: red;
}

.alert-registration {
    padding: 20px 20px 10px 20px;
    margin:  10px 10px 10px 10px;
}

.ant-popover-content {
    max-width: 90vw;
}

.order-form__popover .ant-popover-content {
    font-size: 15px;
}

del{
    display: flex;
    color: rgb(255, 36, 0);
    font-size: 14px;
    font-weight: normal;
}


